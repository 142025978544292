// ** packages **
import { useEffect, useRef, useState } from "react";
import { Plus, Search } from "react-feather";
import { debounce } from "lodash";
// ** components **
import Breadcrumbs from "components/Breadcrumbs";
import Button from "components/Theme/Button";
import AddMeetingForm from "./components/AddMeetingForm";
import DataTableReact, { TableRefType } from "components/DataTable";
// ** hooks **
import useMeetingColumn from "./hooks/useMeetingColumn";
// ** services **
import { useMeetingFolderListAPI } from "./services";
// ** constants **
import { BREAD_CRUMB } from "constants/breadcrumbs.constant";
import usePermission from "hooks/usePermission";
import { PERMISSIONS } from "constants/Permissions.constant";
import NoteSideBar from "modules/DashBoard/sub-modules/Notes/components/NoteSideBar";
import SelectMenu, { optionsType } from "components/SelectMenu";
import { NoteOptions } from "modules/DashBoard/sub-modules/Notes";
import { PRIVATE_NAVIGATION } from "constants/navigation.constant";
import { useNavigate, useParams } from "react-router-dom";

const MeetingSideBar = (props: any) => {
  const { setSearchValue, searchData } = props;
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [isSideOpen, setIsSideOpen] = useState<boolean>(false);
  const tableRef = useRef<TableRefType>(null);
  const navigate = useNavigate();
  const columnDefs = useMeetingColumn();
  const { getMeetingFolderListApi, isLoading } = useMeetingFolderListAPI();
  const { hasPermission } = usePermission();
  const createFolderPermission = hasPermission(PERMISSIONS.NOTES.CREATE);
  const id = useParams();

  const getTableData = async (params: {
    page: number;
    perPage: number;
    sortField: string;
    sortOrder: string;
    search: string;
  }) => {
    let tableData = { rowData: [], rowCount: 10 };
    const { data, error } = await getMeetingFolderListApi({
      params: {
        page: params.page,
        per_page: params.perPage,
        sort_by: params?.sortField,
        sort_order: params?.sortOrder,
        search: searchData ?? params.search,
      },
    });
    if (data && !error) {
      tableData = {
        rowData: data?.data || [],
        rowCount: data?.count || 0,
      };
    }
    return tableData;
  };
  const refreshTable = () => {
    tableRef.current?.refreshData();
  };

  // const onSelect = (value: optionsType) => {
  //   if (value?.title && value?.title?.toLowerCase() !== "meeting") {
  //     navigate(PRIVATE_NAVIGATION.notes.view, {
  //       state: {
  //         note_type: value?.title?.toLowerCase(),
  //         navHighlighter: value?.title?.toLowerCase(),
  //       },
  //     });
  //   }
  // };

  useEffect(() => {
    setSearchValue(tableRef);
  }, [tableRef.current]);

  return (
    <div>
      <NoteSideBar
        setIsSideOpen={setIsSideOpen}
        tableRef={tableRef}
        isSideOpen={isSideOpen}
        note_type={"meeting"}
      />
      {/* <div className="flex md:flex-col items-center md:items-start justify-between mt-[15px] mb-[15px]">
        {createFolderPermission && (
          <div className="flex sm:flex-col items-center sm:items-start gap-2 md:mt-3">
            <Button
              className="btn_with_color_fill !mt-0"
              onClick={() => {
                setOpenForm(true);
              }}
            >
              Add Folder
            </Button>
          </div>
        )}
      </div> */}
      {/* {openForm && createFolderPermission && (
        <AddMeetingForm setIsOpen={setOpenForm} refreshData={refreshTable} />
      )} */}
      <div className="reactDataTable__DSD meetinglist">
        <DataTableReact
          setTableLength={null}
          ref={tableRef}
          columns={columnDefs}
          loader={isLoading}
          getData={(params: {
            page: number;
            perPage: number;
            sortField: string;
            sortOrder: string;
            search: string;
          }) => getTableData(params)}
        />
      </div>
    </div>
  );
};
export default MeetingSideBar;
