// ** packages **
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import * as Icon from "react-feather";

// ** components **
import SvgIcon from "components/Icon/SvgIcon";

// ** constants **
import { PRIVATE_NAVIGATION } from "constants/navigation.constant";
import usePermission from "hooks/usePermission";
import {
  getSidebarIsCollapse,
  setSidebarIsCollapse,
} from "redux/slices/commonSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getAuth } from "redux/slices/authSlice";

export type IconType =
  | "Settings"
  | "Grid"
  | "UserPlus"
  | "User"
  | "Users"
  | "Unlock";
type Props = {
  path: string;
  icon: string;
  label: string;
  currentPath?: string;
  subMenu?: {
    path: string;
    label: string;
    icon: string;
    permission?: {
      name: string;
      type: string;
    };
  }[];
  setIsSidebarClicked: React.Dispatch<React.SetStateAction<boolean>>;
  isSecondSidebarClicked?: boolean;
  setIsSecondSidebarClicked?: React.Dispatch<React.SetStateAction<boolean>>;
};
type subMenuType = {
  path: string;
  label: string;
  icon: string;
  permission?: {
    name: string;
    type: string;
  };
};
type customPathType =
  | "notes"
  | "transcription"
  | "recording"
  | "setting"
  | "adminsetting";
const SidebarNavItem: React.FC<Props> = ({
  path,
  icon,
  label,
  currentPath,
  subMenu,
  setIsSidebarClicked,
  setIsSecondSidebarClicked,
  isSecondSidebarClicked,
}) => {
  const location = useLocation();
  const pathString = location.pathname;
  const locationState = location.state;
  const { user } = useSelector(getAuth);
  const { hasPermission } = usePermission();
  const [isSubMenuOpen, setIsSubmenuOpen] = useState<boolean>(false);
  const linkOfActiveMenu = path;

  const submenuOpen = () => {
    if (isSubMenuOpen) {
      setIsSubmenuOpen(false);
    } else {
      setIsSubmenuOpen(true);
    }
  };

  const isCurrentlyActive = (path: string) => {
    const customPath = {
      notes: "/",
      transcription: "/patient",
      recording: "/",
      setting: "/setting/personalInformation",
      adminsetting: "/adminsetting/super-admin",
    };

    const customPathFromState: any = {
      transcription: {
        voice: PRIVATE_NAVIGATION.voice.view,
        content: PRIVATE_NAVIGATION.content.view,
        meeting: PRIVATE_NAVIGATION.MeetingFolder.view,
      },
      notes: {
        voice: PRIVATE_NAVIGATION.voice.view,
        content: PRIVATE_NAVIGATION.content.view,
        meeting: PRIVATE_NAVIGATION.MeetingFolder.view,
      },
      recording: {
        voice: PRIVATE_NAVIGATION.voice.view,
        content: PRIVATE_NAVIGATION.content.view,
        meeting: PRIVATE_NAVIGATION.MeetingFolder.view,
        patient: PRIVATE_NAVIGATION.patient.view,
      },
    };

    let isActive =
      linkOfActiveMenu === PRIVATE_NAVIGATION.dashboard.view
        ? location.pathname === linkOfActiveMenu
        : location.pathname.startsWith(
            path.substring(0, linkOfActiveMenu.length - 1)
          );

    const isActiveNoState =
      isActive ||
      path === customPath[location.pathname.split("/")[1] as customPathType];
    if (locationState) {
      const currentPath = customPathFromState[location.pathname.split("/")[1]];
      if (currentPath) {
        const activePath = currentPath[locationState?.navHighlighter];
        if (activePath) {
          return isActive || path === activePath;
        } else {
          return isActiveNoState;
        }
      } else {
        return isActiveNoState;
      }
    } else {
      return isActiveNoState;
    }
  };

  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const dispatch = useDispatch();

  const handleResize = () => {
    setWindowSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowSize]);

  const handleSidebarClicked = () => {
    setIsSidebarClicked(true);
    window.innerWidth < 780 && dispatch(setSidebarIsCollapse(false));
  };

  const handleSecondSidebarClick = (path: string) => {
    if (
      path !== "/allnotes" &&
      setIsSecondSidebarClicked &&
      isSecondSidebarClicked
    ) {
      setIsSecondSidebarClicked(false);
      return;
    }
  };

  const checkActive = (subItem: subMenuType) => {
    if (pathString.slice(0, 13) === "/setting/user") {
      return pathString.slice(0, 13) === subItem.path;
    } else {
      return pathString === subItem.path;
    }
  };
  return (
    <div
      className={`s__Menu__Item flex flex-col justify-center w-full max-w-full ${
        isCurrentlyActive(path)
          ? // remove the second active if not wanted to show double high-lighter from the below condition
            `${!isSecondSidebarClicked ? "active" : "active"} rounded w-full`
          : ""
      }`}
      key={path}
    >
      {!subMenu ? (
        <Link
          to={path}
          className="s__Menu__Item__Link group cursor-pointer relative flex w-full items-center duration-300 gap-[12px] pr-4 py-2 pl-[25px]"
          onClick={() => {
            submenuOpen();
            handleSidebarClicked();
            handleSecondSidebarClick(path);
          }}
          key={label}
        >
          <div className="s__Menu__Icon !bg-transparent w-[20px] min-w-[20px] d-flex justify-center">
            <SvgIcon iconName={icon} className="!w-[20px] !h-[20px]" />
          </div>

          <div className="s__Menu__Text">{label}</div>
          {subMenu && (
            <div className="s__Menu__Arrow absolute top-[50%] translate-y-[-50%] -right-[9px]">
              {isSubMenuOpen ? <Icon.ChevronDown /> : <Icon.ChevronRight />}
            </div>
          )}
        </Link>
      ) : (
        <div
          className="s__Menu__Item__Link group cursor-pointer relative flex w-full items-center duration-300 gap-[12px] pr-4 py-2 pl-6"
          onClick={submenuOpen}
          key={label}
        >
          <div className="s__Menu__Icon__Main !bg-transparent w-[20px] min-w-[20px] d-flex justify-center">
            {<SvgIcon iconName={icon} className="" />}
          </div>
          <div className="s__Menu__Text p-0">{label}</div>
          {subMenu && (
            <div className="s__Menu__Arrow">
              {isSubMenuOpen ? <Icon.ChevronDown /> : <Icon.ChevronRight />}
            </div>
          )}
        </div>
      )}
      {subMenu ? (
        <div className={`submenu__wrapper flex flex-col gap-5 pt-4 pl-9`}>
          {isSubMenuOpen &&
            subMenu?.map((item: subMenuType) => {
              const isActiveSubMenu = checkActive(item);
              const permitted =
                !item.permission || hasPermission(item.permission);
              return permitted ? (
                <Link
                  onClick={() => {
                    handleSidebarClicked();
                    handleSecondSidebarClick(path);
                  }}
                  to={item.path}
                  className={`s__Menu__Item__Link submenu_item group flex items-center pl-3 gap-2 ${
                    isActiveSubMenu ? "active" : ""
                  }`}
                  key={item.path}
                >
                  <div className="s__Menu__Icon !bg-transparent w-[15px] h-[15px]">
                    <SvgIcon iconName={item.icon} className="" />
                  </div>
                  <div
                    className={`s__Menu__Text !w-[calc(100%-30px)] max-w-full ${
                      !isActiveSubMenu && "!text-black"
                    }`}
                  >
                    {item.label}
                  </div>
                </Link>
              ) : (
                <React.Fragment key={item.path} />
              );
            })}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
export default SidebarNavItem;
