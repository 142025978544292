import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { getAuth, specificationType } from "redux/slices/authSlice";
type stopWatchPropsType = {
  isRunningTime: boolean;
  isPausedTime: boolean;
  setIsRecording: (props: boolean) => void;
  stopRecording: () => void;
};

const StopWatch = (props: stopWatchPropsType) => {
  const { isRunningTime, isPausedTime, setIsRecording, stopRecording } = props;
  const [elapsedTime, setElapsedTime] = useState(0);
  const [pauseTime, setPauseTime] = useState(0);
  // const [isTimeLimit, setIsTimeLimit] = useState<boolean>(false);
  const [value, setValue] = useState([0, 1, 2, 3]);
  const { user } = useSelector(getAuth);
  const plan = user?.current_plan?.payment_data?.plan;
  const planName = plan?.plan_name?.toLocaleLowerCase();
  const specification = plan?.specification;

  let isTimeLimit = true;
  let recordLimit = 0;
  if (specification) {
    specification.forEach((name: specificationType) => {
      if (name?.name_type?.toLocaleLowerCase() === "recording" && name?.limit) {
        if (String(name.limit) === "-1") {
          isTimeLimit = false;
          recordLimit = 0;
        } else {
          recordLimit = +name?.limit * 60;
        }
        return;
      }
    });
  }

  useEffect(() => {
    if (planName) {
      setElapsedTime(recordLimit ?? 0);
    }
  }, []);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isRunningTime && !isPausedTime) {
      intervalId = setInterval(() => {
        if (isTimeLimit) {
          setElapsedTime((prevElapsedTime) => {
            if (prevElapsedTime) {
              return prevElapsedTime - 1;
            } else {
              setIsRecording(false);
              stopRecording();
              return 0;
            }
          });
        } else {
          setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
        }
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isRunningTime, isPausedTime]);
  useEffect(() => {
    if (isPausedTime) {
      setPauseTime(elapsedTime);
    }
  }, [isPausedTime, elapsedTime]);

  useMemo(() => {
    if (elapsedTime >= value[value.length - 1]) {
      if (value.length > 0) {
        value.shift();
      }
      value.push(elapsedTime);
    }
    setValue(value);
  }, [elapsedTime]);

  const formatTime = (timeInSeconds: number) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  };

  return (
    <>
      <div className="text-center font-[500]">
        {formatTime(isPausedTime ? pauseTime : elapsedTime)}
      </div>
      {/* <div className="audiowaves">
        <div className="wavesvalue">
          {value?.map((item, index) => (
            <div className="" key={index}>{item}</div>
          ))}
        </div>
      </div> */}
    </>
  );
};

export default StopWatch;
