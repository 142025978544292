import { AxiosRequestConfig } from "axios";
import { useAxiosGet, useAxiosPost, useAxiosPut } from "hooks/useaxios";

const TRANSCRIPTION_API_BASE_PATH = "/transcript";

export const useUpdateTranscriptAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
  const updateTranscriptAPI = async (id: number, payload: object) => {
    const response = await callApi(
      `${TRANSCRIPTION_API_BASE_PATH}/update_transcript/${id}`,
      payload
    );
    return response;
  };

  return { updateTranscriptAPI, isLoading, isError, isSuccess };
};

export const useExportTranscriptAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const exportTranscriptAPI = async (
    data: object,
    verify_token: string | null,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${TRANSCRIPTION_API_BASE_PATH}/export?verify_token=${verify_token}`, data, config);
  };
  return { exportTranscriptAPI, isLoading, isError, isSuccess };
};

export const useUpdateTranscriptUrlAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
  const updateTranscriptUrlAPI = async (id: number, payload: object) => {
    const response = await callApi(
      `${TRANSCRIPTION_API_BASE_PATH}/generate_shared_url/${id}`,
      payload
    );
    return response;
  };

  return { updateTranscriptUrlAPI, isLoading, isError, isSuccess };
};

export const useUpdateSharedTranscriptAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
  const updateSharedTranscriptAPI = async (params: string, payload: object) => {
    const response = await callApi(
      `${TRANSCRIPTION_API_BASE_PATH}/update_shared_transcript?${params}`,
      payload
    );
    return response;
  };

  return { updateSharedTranscriptAPI, isLoading, isError, isSuccess };
};

export const useDeleteTranscriptAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const deleteTranscriptAPI = async (id: number | string) => {
    return callApi(
      `${TRANSCRIPTION_API_BASE_PATH}/delete_transcript/${id}`,
      {}
    );
  };
  return { deleteTranscriptAPI, isLoading, isError, isSuccess };
};


export const useAllDeleteTranscriptAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const allDeleteTranscriptAPI = async (select_delete_list: number[]) => {
    return callApi(
      `${TRANSCRIPTION_API_BASE_PATH}/select_all_delete_transcript`,
      {select_delete_list}
    );
  };
  return { allDeleteTranscriptAPI, isLoading, isError, isSuccess };
};

export const useGetNotesCountAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getNotesCountApi = async () => {
    return callApi(`${TRANSCRIPTION_API_BASE_PATH}/notes_count`);
  };

  return { getNotesCountApi, isLoading, isError, isSuccess };
};

export const useRetryTranscriptAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();

  const retryTranscriptAPI = async (id: number) => {
    return callApi(
      `${TRANSCRIPTION_API_BASE_PATH}/retry_transcript/${id}`,
      {}
    );
  };

  return { retryTranscriptAPI, isLoading, isError, isSuccess };
};

export const useShareTranscriptViaEmailAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const shareTranscriptViaEmailAPI = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${TRANSCRIPTION_API_BASE_PATH}/user_invitations`, data, config);
  };
  return { shareTranscriptViaEmailAPI, isLoading, isError, isSuccess };
};

export const useGetUserSuggestionAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getUserSuggestionAPI = async () => {
    return callApi(`${TRANSCRIPTION_API_BASE_PATH}/get_users_suggestion`);
  };

  return { getUserSuggestionAPI, isLoading, isError, isSuccess };
};