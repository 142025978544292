// ** packages **
import { ChevronRight, LogOut, ChevronLeft, Plus } from "react-feather";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Ref, useEffect, useRef, useState } from "react";

// ** components **
import SidebarNavItem, { IconType } from "./SidebarNavItems";

// ** CSS **
import "./style/sidebar.css";

// ** Redux **
import {
  getSidebarIsCollapse,
  setSidebarIsCollapse,
} from "redux/slices/commonSlice";
import { getAuth, setLogoutData } from "redux/slices/authSlice";

// ** constants **
import { ADMIN_NAVIGATION_ITEM, NAVIGATION_ITEM } from "../constants";
import usePermission from "hooks/usePermission";
import AlertModal from "components/modal/AlertModal";
import { PRIVATE_NAVIGATION } from "constants/navigation.constant";
import ImageShow from "components/Image";
import { TableRefType } from "components/DataTable";
import SelectMenu, { optionsType } from "components/SelectMenu";
import NoteSideBar from "modules/DashBoard/sub-modules/Notes/components/NoteSideBar";
import { NoteOptions } from "modules/DashBoard/sub-modules/Notes";
import Button from "components/Theme/Button";
import { PERMISSIONS } from "constants/Permissions.constant";
import { ContentNotesIcon } from "components/Icon/SvgIcon/ContentNotesIcon";
import { NoteOptionSidebar } from "./Sidebar2";
import { itemType, navItem, navItemsType, noteOptionsType } from "../types";

type Props = {
  sidebarRef: Ref<HTMLDivElement>;
  headerTitle: string;
  profile?: string | null;
  setIsSidebarClicked: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSecondSidebarClicked: React.Dispatch<React.SetStateAction<boolean>>;
  isSidebarClicked: boolean;
  isSecondSidebarClicked: boolean;
};

const Sidebar = (props: Props) => {
  const {
    sidebarRef,
    headerTitle,
    profile,
    setIsSidebarClicked,
    isSidebarClicked,
    isSecondSidebarClicked,
    setIsSecondSidebarClicked,
  } = props;
  const [logoutVisible, setLogoutVisible] = useState<boolean>(false);
  const sidebarIsCollapse = useSelector(getSidebarIsCollapse);

  const [isSideOpen, setIsSideOpen] = useState<boolean>(false);
  const [selectValue, setSelectValue] = useState<string>("");
  const tableRef = useRef<TableRefType>(null);
  const { hasPermission } = usePermission();
  const createNotesPermission = hasPermission(PERMISSIONS.NOTES.CREATE);
  const readNotesPermission = hasPermission(PERMISSIONS.NOTES.READ);
  const readPatientPermission = hasPermission(PERMISSIONS.PATIENT.READ);
  const location = useLocation();
  const { state } = useLocation();
  const navigate = useNavigate();

  const currentPath = location.pathname;
  const dispatch = useDispatch();
  const { user } = useSelector(getAuth);
  const navigationData = user?.is_super_administrator
    ? ADMIN_NAVIGATION_ITEM
    : NAVIGATION_ITEM;
  const handleCollapse = () => {
    dispatch(setSidebarIsCollapse(!sidebarIsCollapse));
  };
  const onSubmit = () => {
    dispatch(setLogoutData());
    setLogoutVisible(false);
  };

  const NoteSecondOption: noteOptionsType[] = NoteOptionSidebar?.filter(
    (item: navItem) => {
      return !item.permission || hasPermission(item.permission);
    }
  );
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  // If no selectValue, set it to the first option by default
  // useEffect(() => {
  //   if (!selectValue && NoteSecondOption.length > 0) {
  //     setSelectValue(NoteSecondOption[0].title!.toLowerCase());
  //
  // }, [selectValue, NoteSecondOption]);

  useEffect(() => {
    // if (state?.note_type || state?.mode) {
    if (state?.note_type) {
      setIsSecondSidebarClicked(true);
    }
  }, [state]);

  const onSelect = (value: optionsType) => {
    const newValue = value?.title?.toLowerCase();
    setSelectValue(newValue!);
    setIsSideOpen(true);
  };

  const handleSecondSidebarClick = (path: string) => {
    if (path === "/allnotes" && setIsSecondSidebarClicked) {
      setIsSecondSidebarClicked(!isSecondSidebarClicked);
      if (windowSize <= 1200) {
        dispatch(setSidebarIsCollapse(false));
      }
      return;
    }
  };

  const handleSelectNote = () => {
    if (!sidebarIsCollapse) {
      handleCollapse();
    }
  };

  useEffect(() => {
    window.addEventListener("resize", () => setWindowSize(window.innerWidth));

    if (sidebarIsCollapse && windowSize <= 1200) {
      handleCollapse();
      // setIsSecondSidebarClicked(true);
    }

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", () =>
        setWindowSize(window.innerWidth)
      );
    };
  }, [windowSize]);

  return (
    <aside
      className="sidebar__wrapper duration-300 ease-linear bg-white"
      ref={sidebarRef}
    >
      <div className="header__logo flex items-center px-4 py-7 sm:flex-col sm:p-3 mb-5">
        <div
          onClick={() => {
            navigate(PRIVATE_NAVIGATION.setting.personalInformation.view);
          }}
          className="cursor-pointer s__Menu__Item"
        >
          <ImageShow
            avatarWrapperClassName={
              !sidebarIsCollapse
                ? `w-[40px] h-[40px] sm:w-[45px] sm:h-[45px]`
                : `w-[50px] h-[50px] sm:w-[45px] sm:h-[45px]`
            }
            avatarInnerWrapperClassName="!rounded-full"
            imgPath={profile}
            first_name={headerTitle}
            last_name={headerTitle}
          />
        </div>
        {sidebarIsCollapse && (
          <p className="text-[20px] ml-3 sm:text-[15px] sm:w-min sm:leading-[12px]">
            <span className="font-semibold">{`${headerTitle}`}</span>
          </p>
        )}
      </div>
      {createNotesPermission && (
        <div className="sticky top-0 z-[2] rounded-lg bg-[#6558F5] text-white mx-4 new-note-btn">
          <NoteSideBar
            setIsSideOpen={setIsSideOpen}
            tableRef={tableRef}
            isSideOpen={isSideOpen}
            note_type={selectValue}
          />
          <SelectMenu
            publishingOptions={NoteSecondOption}
            onSelect={onSelect}
            title="New Note"
            titleIcon={
              <Plus className="inline mx-1 size-sm w-4 h-4" strokeWidth="4" />
            }
            needHover={false}
            selected={{ id: null, title: selectValue }}
            wantTickFromStatus={false}
            wantCheckInTitle={false}
            onTextClick={handleSelectNote}
          />
        </div>
      )}

      <button
        onClick={() => handleCollapse()}
        className="collapse__btn absolute top-[42px] right-[-14px] flex items-center justify-center p-[3px] w-[28px] h-[28px] rounded-full bg-[#6558F5]"
      >
        {!sidebarIsCollapse ? (
          <>
            {/* {setIsSecondSidebarClicked(false)} */}
            <ChevronRight />
          </>
        ) : (
          <ChevronLeft />
        )}
      </button>

      <div className="sidebar__content p-5">
        <div
          className={`menu__wrapper ${
            sidebarIsCollapse
              ? `${
                  !user?.is_super_administrator
                    ? "h-[calc(100vh_-_350px)]"
                    : "h-[calc(100vh_-_306px)]"
                }`
              : `${
                  !user?.is_super_administrator
                    ? "h-[calc(100vh_-_320px)]"
                    : "super-logout"
                } overflow-x-hidden`
          } overflow-y-auto`}
        >
          <div className="sidenav-menu overflow-y-auto overflow-x-hidden flex flex-col items-center gap-4 relative ">
            {navigationData
              ?.filter((item: navItemsType) => {
                return !item?.permission || hasPermission(item?.permission);
              })
              .map((filteredItem: navItemsType) => {
                return filteredItem?.path !== "/allnotes" ? (
                  <SidebarNavItem
                    key={filteredItem?.label}
                    path={filteredItem.path}
                    icon={filteredItem.icon}
                    label={filteredItem.label}
                    currentPath={currentPath}
                    subMenu={filteredItem?.subItems}
                    setIsSidebarClicked={setIsSidebarClicked}
                    isSecondSidebarClicked={isSecondSidebarClicked}
                    setIsSecondSidebarClicked={setIsSecondSidebarClicked}
                  />
                ) : (readNotesPermission || readPatientPermission) &&
                  user?.current_plan?.active ? (
                  <div
                    key={filteredItem.label}
                    className={`s__Menu__Item side-menu pr-4 py-2 pl-6 flex items-center w-full rounded ${
                      isSecondSidebarClicked && "active "
                    }`}
                  >
                    <div
                      className="flex items-center w-full max-w-full gap-[12px]"
                      onClick={() => {
                        handleSecondSidebarClick(filteredItem?.path);
                        // if(visualViewport){
                        //   if(visualViewport?.width <= 1200){
                        //     handleCollapse()
                        //   }
                        // }
                      }}
                    >
                      <div
                        className="side-menu-icon"
                        // onClick={() => handleCollapse()}
                      >
                        <ContentNotesIcon className="h-[20px] w-[20px]" />
                      </div>
                      <div className="icon-text w-full">
                        {" "}
                        {isSecondSidebarClicked ? (
                          <div className="flex cursor-pointer justify-between w-full max-w-full white">
                            <span className="whitespace-nowrap view-notes">
                              {" "}
                              Hide Notes
                            </span>{" "}
                            <ChevronLeft className="w-[24px]" />
                          </div>
                        ) : (
                          <div className="flex cursor-pointer justify-between w-full">
                            <span className="whitespace-nowrap view-notes">
                              {" "}
                              View Notes{" "}
                            </span>
                            <ChevronRight className="w-[24px]" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                );
              })}
          </div>
        </div>
      </div>

      <div className="sidebar-bottom">
        <div className="s__Menu__Item px-5 py-7 w-full">
          <img
            className="w-full object-contain uncollapse-logo"
            src="/images/dsd-logo.png"
            alt=""
          />
          <img
            className="h-[30px] w-auto block object-contain dsd-icon collapse-logo"
            src="/images/dsd-icon.svg"
            alt=""
          />
          {/* <img
            className="collapse-logo"
            src="/images/collapse-dsd-logo.png"
            alt=""
          /> */}
        </div>
        <div
          className="s__Menu__Item logout__btn w-full bg-[#222222] group hover:bg-bgBlackSD flex justify-start items-center p-5"
          onClick={() => {
            setLogoutVisible(true);
          }}
        >
          <Link
            className="s__Menu__Item__Link group flex items-center justify-start gap-2"
            to="#"
          >
            <LogOut className="text-white" />
            <div className="s__Menu__Text !text-[#ffffff]">Logout</div>
            {/* <div className="s__Menu__Icon bg-transparent"></div> */}
          </Link>
        </div>
      </div>

      <AlertModal
        onCancel={() => setLogoutVisible(false)}
        onClose={() => setLogoutVisible(false)}
        onSubmit={onSubmit}
        showFooter
        showCrossIcon
        visible={logoutVisible}
        submitButtonText="Logout"
      >
        <div className="text-center">Are you sure you want to log out ? </div>
      </AlertModal>
    </aside>
  );
};
export default Sidebar;
