import { IconInputProps } from ".";

export const SolidLockIcon = ({ className }: IconInputProps) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 12V26C28 26.5304 27.7893 27.0391 27.4142 27.4142C27.0391 27.7893 26.5304 28 26 28H6C5.46957 28 4.96086 27.7893 4.58579 27.4142C4.21071 27.0391 4 26.5304 4 26V12C4 11.4696 4.21071 10.9609 4.58579 10.5858C4.96086 10.2107 5.46957 10 6 10H10V7C10.0017 5.40921 10.6343 3.88405 11.7592 2.75919C12.884 1.63433 14.4092 1.00165 16 1C18.9 1 21.415 3.01875 21.98 5.80125C22.0298 6.06 21.9755 6.32795 21.8288 6.54686C21.6822 6.76577 21.455 6.91795 21.1968 6.97032C20.9385 7.0227 20.6701 6.97103 20.4497 6.82655C20.2294 6.68207 20.0749 6.45646 20.02 6.19875C19.65 4.375 17.9213 3 16 3C14.9391 3 13.9217 3.42143 13.1716 4.17157C12.4214 4.92172 12 5.93913 12 7V10H26C26.5304 10 27.0391 10.2107 27.4142 10.5858C27.7893 10.9609 28 11.4696 28 12Z"
        fill="#4b5c6b"
      />
    </svg>
  );
};
