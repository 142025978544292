
import { SuperAdminPlanSchemaError } from "constants/formErrorMessage.constant";
import * as yup from "yup";
import "yup-phone-lite";


export const SuperAdminPlanSchema = yup.object().shape({
  plan_name: yup.string().trim()
  .min(3, SuperAdminPlanSchemaError.title_min)
  .max(50, SuperAdminPlanSchemaError.title_max)
  .matches(/^[a-zA-Z\s]+$/, SuperAdminPlanSchemaError.onlyAlphanumeric)
  .required(SuperAdminPlanSchemaError?.plan_name),
  description: yup.string().trim()
  .min(3, SuperAdminPlanSchemaError.description_min)
  .max(250, SuperAdminPlanSchemaError.description_max)
  .matches(/^[a-zA-Z\s]+$/, SuperAdminPlanSchemaError.onlyAlphanumeric)
  .required(SuperAdminPlanSchemaError.description),
  price_id: yup.object().shape({
    label: yup.string().required("Price label is required"),
    value: yup.string().required("Price value is required"),
  }).required("Price is required"),

  // // the validation is not working with 100 per cent accurayc
  // is_hidden: yup.boolean().required("is_hidden is required"),
  // price_id: yup.object().shape({
  //   label: yup.string().required("Price label is required"),
  //   value: yup.string().required("Price value is required"),
  // }).test('price_id-required', 'Price is required', function(value) {
  //   const { is_hidden } = this.parent;
  //   if (is_hidden) {
  //     return true;
  //   }
  //   if (value && typeof value === 'object') {
  //     return Object.values(value.label).length > 0 && Object.values(value.value).length > 0; // Ensure both properties are present
  //   }
  //   return false;
  // }),


  specification: yup.array().of(
    yup.object().shape({
      // id: yup.number().required("ID is required"), // Keep this as is
      // name_type: yup.string().required("Name type is required"),
      limit: yup.mixed()
      .required("Limit is required")
      .test("is-string-or-boolean", "Limit must be a string or boolean",
        value => typeof value === "string" || typeof value === "boolean"),
      // name: yup.string().required("Specification name is required"),
    })
  ).required("Specifications are required"),
});


  

  // specification: yup.array()
  //   .of(
  //     yup.object().shape({
  //       name_type: yup.string().required("Name type is required"),
  //       limit: yup.string()
  //         .oneOf(["true", "false"], SuperAdminPlanSchemaError.specification_limit)
  //         .required("Limit is required"),
  //       name: yup.string().required("Name is required"),
  //     })
  //   )
  //   .min(1, SuperAdminPlanSchemaError.specifications_required)
  //   .required("Specifications are required"),