import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootStateType } from "../store";
import { logoutHelper } from "../../modules/Auth/helper/logout.helper";

export type Permission = {
  id: number;
  types: string;
  status: boolean;
  permission_name: string;
};

export type specificationType = {
  // specification: {
    id: number;
    limit: number;
    name: string;
    name_type: string;
    permission: {
      permission_name: string;
      types: string;
    };
  // };
};

export type paymentDataType = {
  plan: {
    description: string;
    duration: string;
    id: number;
    plan_name: string;
    price: number;
    is_admin_show?:boolean;
    specification: specificationType[];
  };
};

type currentPlanType = {
  active: boolean;
  created_at: string;
  current_period_end: string | null;
  email: string;
  id: number;
  payment_data: paymentDataType;
};

export type AuthUserType = {
  roles: string | null;
  id: number | null;
  firstName: string | null;
  lastName: string | null;
  phoneNumber: number | null;
  email: string | null;
  profile: string | null;
  is_super_administrator: boolean;
  permissions: Permission[] | null;
  current_plan?: currentPlanType | null;
};

type AuthSliceType = {
  user?: Partial<AuthUserType | null>;
  isAuthenticated?: boolean;
  isAuthInitialized?: boolean;
  resendMailId?: string | null;
};

const initialState: AuthSliceType = {
  user: null,
  isAuthenticated: false,
  isAuthInitialized: false,
  resendMailId: null,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCurrentUser(state: AuthSliceType, action: PayloadAction<AuthSliceType>) {
      state.user = action.payload.user;
    },
    setAuthenticated(
      state: AuthSliceType,
      action: PayloadAction<AuthSliceType>
    ) {
      state.isAuthenticated = action.payload.isAuthenticated;
    },
    setAuthInitialized(state: AuthSliceType) {
      state.isAuthInitialized = true;
    },
    setCurrentPlan(
      state: AuthSliceType,
      action: PayloadAction<currentPlanType>
    ) {
      if (state.user?.current_plan) {
        state.user.current_plan.payment_data.plan =
          action.payload.payment_data?.plan;
      } else {
        if (state.user) {
          state.user.current_plan = action.payload;
        }
      }
    },
    setLogoutData(state: AuthSliceType) {
      state.user = null;
      state.isAuthenticated = false;
      logoutHelper();
    },
  },
});
export const { reducer } = slice;
export const {
  setCurrentUser,
  setCurrentPlan,
  setAuthenticated,
  setAuthInitialized,
  setLogoutData,
} = slice.actions;

export const getAuth = (state: RootStateType) => state.auth;

export const getIsAuthenticated = (state: RootStateType) =>
  state.auth.isAuthenticated;
export const getResendMailId = (state: RootStateType) =>
  state.auth.resendMailId;