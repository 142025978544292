// ** packages **
import { useEffect, useRef, useState } from "react";
import { Search } from "react-feather";
import { debounce } from "lodash";

// ** components **
import DataTableReact, { TableRefType } from "components/DataTable";
import Button from "components/Theme/Button";
// ** services **
import {
  useDeletePlaybookAPI,
  useGetPlaybookTemplateAPI,
  usePlaybookTemplatesList,
  useUpdateStatusTemplatePlaybookAPI,
} from "../services";

// ** constant **
import PlaybookForm from "./PlaybookForm";
import PlayBookColumn from "../hooks/PlaybookColumn";
import {
  AlertModalType,
  isOpenStateType,
  promptStatusType,
} from "../types/prompt.types";
import AlertModal from "components/modal/AlertModal";
import { tipTapNoteType } from "modules/Patient/sub-modules/Recording/types/recording.types";
import usePermission from "hooks/usePermission";
import { PERMISSIONS } from "constants/Permissions.constant";
import { firstCharUpperCase } from "utils";

// ** Types **

const PlaybookTemplateList = () => {
  const tableRef = useRef<TableRefType>(null);
  const [isNoteOpen, setIsNoteOpen] = useState<boolean>(false);
  const [tableLength, setTableLength] = useState<number>(0);
  const [currentNote, setCurrentNote] = useState<tipTapNoteType>({
    id: 0,
    content: "",
    title: "Template-1",
    description: "",
  });
  const [playbookStatus, setPlaybookStatus] = useState<promptStatusType>({
    visible: false,
    id: null,
    method: null,
    status: null,
    currentState: null,
    lastDbState: null,
  });
  const onChangeStatus = async (
    id: string | number,
    method: AlertModalType | null,
    status: string | null,
    isDefault: boolean | null
  ) => {
    setPlaybookStatus({
      visible: true,
      id: id,
      method: method,
      status: status,
      currentState: null,
      lastDbState: null,
    });
  };
  const getPlayBook = useGetPlaybookTemplateAPI();
  const [isOpen, setIsOpen] = useState<isOpenStateType>({
    open: false,
    id: null,
    isDefault: false,
    viewOnly: true,
  });
  const { hasPermission } = usePermission();
  const playbookCreatePermission = hasPermission(PERMISSIONS.PLAYBOOK.CREATE);

  useEffect(() => {
    if (isOpen.open && isOpen.id) {
      const loadData = async (id: number) => {
        const { data, error } = await getPlayBook.getPlaybookTemplateApi(id);
        const noteTypes = data?.playbook_type?.map((item: { type: string }) => {
          return {
            title: `Save as ${firstCharUpperCase(item.type)}`,
            value: item.type,
          };
        });
        if (data) {
          setCurrentNote({
            id: data?.id,
            content: data?.content,
            title: data?.title,
            description: data?.description,
            type: noteTypes,
          });
          setIsNoteOpen(true);
        } else {
          setIsNoteOpen(false);
        }
      };
      loadData(isOpen.id);
    }
  }, [isOpen]);

  const columnDefs = PlayBookColumn({ setIsOpen, onChangeStatus });

  const { getPlaybookListApi, isLoading } = usePlaybookTemplatesList();
  const { deletePlaybookApi, isLoading: isDeleteLoading } =
    useDeletePlaybookAPI();
  const updateStatusPlaybookAPI = useUpdateStatusTemplatePlaybookAPI();
  const getTableData = async (params: {
    page: number;
    perPage: number;
    sortField: string;
    sortOrder: string;
    search: string;
  }) => {
    let tableData = { rowData: [], rowCount: 10 };
    const { data, error } = await getPlaybookListApi({
      params: {
        page: params.page,
        per_page: params.perPage,
        sort_by: params?.sortField,
        sort_order: params?.sortOrder,
        search: params.search,
      },
    });
    if (data && !error) {
      tableData = {
        rowData: data || [],
        rowCount: data?.count || 0,
      };
    }

    return tableData;
  };

  const HandleAdd = () => {
    setCurrentNote({
      id: 0,
      content: "",
      title: "Template-1",
      description: "",
      type: [],
    });
    setIsNoteOpen(true);
  };

  const deletePlaybook = async () => {
    if (playbookStatus?.id && playbookStatus?.method === "delete") {
      const { error } = await deletePlaybookApi(playbookStatus?.id);
      if (!error) {
        setPlaybookStatus({
          id: null,
          visible: false,
          method: null,
          status: null,
          currentState: null,
          lastDbState: null,
        });
        refreshTable();
      }
    }
  };
  const refreshTable = () => {
    tableRef.current?.refreshData();
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    tableRef.current?.onChange?.(e);
  };

  const onClose = () => {
    setPlaybookStatus({
      id: null,
      visible: false,
      method: null,
      status: null,
      currentState: null,
      lastDbState: null,
    });
    refreshTable();
  };

  const onSubmit = async () => {
    if (playbookStatus?.method === "delete") {
      deletePlaybook();
    } else if (playbookStatus?.method === "status") {
      if (playbookStatus?.id) {
        await updateStatusPlaybookAPI.updateStatusTemplatePlaybookAPI(
          {},
          playbookStatus?.id
        );
        setPlaybookStatus({
          id: null,
          visible: false,
          method: null,
          status: null,
          currentState: null,
          lastDbState: null,
        });
      }
    }

    refreshTable();
  };

  return (
    <div className="bg-bgWhiteSD rounded-[10px] shadow-raiseShadow p-[24px] sm:p-[12px] pb-[16px] mt-[20px] overflow-y-hidden h-[calc(100dvh-130px)] ">
      {!isNoteOpen && (
        <>
          {playbookCreatePermission && (
            <div className="block float-end mt-[15px] mb-[15px]">
              <div>
                <Button
                  className="bg-[#6558F5] text-white md:mt-4"
                  onClick={HandleAdd}
                >
                  Add Playbook
                </Button>
              </div>
            </div>
          )}
          {/* <div className="flex md:flex-col items-center md:items-start justify-between mt-[15px] mb-[15px]">
            <div className="form__group">
              <div className="field__wrapper mb-0">
                <div className="field__inner__wrapper field__has__icon icon__position__left">
                  <input
                    className="input__DSD pl-11"
                    type="text"
                    placeholder="Search"
                    onChange={debounce(onSearchChange, 300)}
                  />
                  <div className="icon__wrapper">
                    <Search />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="reactDataTable__DSD play-book-table">
            <DataTableReact
              ref={tableRef}
              columns={columnDefs}
              loader={isLoading}
              getData={(params: {
                page: number;
                perPage: number;
                sortField: string;
                sortOrder: string;
                search: string;
              }) => getTableData(params)}
              setTableLength={setTableLength}
            />
          </div>
        </>
      )}
      <PlaybookForm
        currentNote={currentNote}
        setCurrentNote={setCurrentNote}
        isNoteOpen={isNoteOpen}
        setIsNoteOpen={setIsNoteOpen}
        refreshTable={refreshTable}
      />
      <AlertModal
        visible={playbookStatus?.visible}
        showCrossIcon
        onSubmit={onSubmit}
        onCancel={onClose}
        onClose={onClose}
        submitButtonText="Yes"
        submitLoading={isDeleteLoading || updateStatusPlaybookAPI.isLoading}
        secondaryLoading={isDeleteLoading || updateStatusPlaybookAPI.isLoading}
      >
        <p className="text-md mb-3 flex ">
          {playbookStatus?.method?.includes("draft") ? (
            <span>
              Do you want to save{" "}
              {playbookStatus?.method === "draft update" && "latest"} changes as
              draft?
            </span>
          ) : (
            <span>
              Are you sure you want to{" "}
              {playbookStatus?.method === "delete"
                ? "delete"
                : playbookStatus?.status == "Active"
                ? "Inactive"
                : "Active"}{" "}
              this playbook ?
            </span>
          )}
        </p>
      </AlertModal>
    </div>
  );
};

export default PlaybookTemplateList;
