// ** packages **
import { useEffect, useState } from "react";
import DataTableReact from "components/DataTable";

// ** hooks **
import { useLocation, useNavigate } from "react-router-dom";

// ** services **
import { useMeetingTranscriptListAPI } from "../services";
import { meetingAllNotesTypeProps } from "../schema/Meeting.schema";
import {
  useAllDeleteTranscriptAPI,
  useDeleteTranscriptAPI,
  useRetryTranscriptAPI,
} from "modules/Patient/sub-modules/Transcription/services";
import AlertModal from "components/modal/AlertModal";
import Button from "components/Theme/Button";
import usePermission from "hooks/usePermission";
import { PERMISSIONS } from "constants/Permissions.constant";
import { PRIVATE_NAVIGATION } from "constants/navigation.constant";
import useMeetingNotesColumnSidebar from "../hooks/useMeetingNotesColumnSidebar";
import { ChevronLeft, Edit3, Mic, Upload } from "react-feather";
import DraggableFileInput from "components/DraggableFileInput";
import { allowed_mimetype } from "modules/Patient/sub-modules/Recording/constant";
import { useAddAudioRecordingAPI } from "modules/Patient/sub-modules/Recording/Services";
import UploadDraggableFile from "./UploadDraggableFile";
import AddMeetingForm from "./AddMeetingForm";

const MeetingFolderAllNotes = (props: meetingAllNotesTypeProps) => {
  const {
    meetingId,
    tableRef,
    setSearchValue,
    searchData,
    setIsParamID,
    // setFolderName,
  } = props;
  const [currentRetryId, setCurrentRetryId] = useState<string | number | null>(
    null
  );
  const [open, setOpen] = useState<boolean>(false);
  const [openUploadFile, setOpenUploadFile] = useState<boolean>(false);
  const [folderName, setFolderName] = useState<string | null>("");
  const [selectedIdsArr, setSelectedIdsArr] = useState<number[]>([]);
  const [currentID, setCurrentID] = useState<number | string>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { state } = useLocation();
  const { retryTranscriptAPI, isLoading: isRetryLoading } =
    useRetryTranscriptAPI();
  const navigate = useNavigate();
  const { getMeetingTranscriptGetAPI, isLoading } = useMeetingTranscriptListAPI(
    meetingId ?? ""
  );
  const location = useLocation();
  const { hasPermission } = usePermission();
  const hasNoteDeletePermission = hasPermission(PERMISSIONS.NOTES.DELETE);

  const { deleteTranscriptAPI, isLoading: isDeleteLoading } =
    useDeleteTranscriptAPI();
  const { allDeleteTranscriptAPI } = useAllDeleteTranscriptAPI();
  const [backIsMic, setBackIsMic] = useState<boolean>(false);

  const getTableData = async (params: {
    page: number;
    perPage: number;
    sortField: string;
    sortOrder: string;
    search: string;
  }) => {
    let tableData = { rowData: [], rowCount: 10 };
    const { data, error } = await getMeetingTranscriptGetAPI({
      params: {
        page: params.page,
        per_page: params.perPage,
        sort_by: params?.sortField,
        sort_order: params?.sortOrder,
        search: searchData ?? params.search,
      },
    });
    if (data && !error) {
      tableData = {
        rowData: data?.data || [],
        rowCount: data?.count || 0,
      };
    }

    if (data && !error) {
      setFolderName(data?.folder_name?.name);
    }
    return tableData;
  };

  const refreshTable = () => {
    tableRef.current?.refreshData();
  };

  useEffect(() => {
    if (state?.mode === "meeting") {
      refreshTable();
    }
  }, [state, folderName]);

  const onRetryTranscript = async (id: number | undefined) => {
    if (id) {
      setCurrentRetryId(id);
      await retryTranscriptAPI(id);
      refreshTable();
    }
  };
  const onDeleteClick = async (id: number | string) => {
    setOpen(true);
    setCurrentID(id);
  };

  const onDeleteSubmit = async (id: number | string) => {
    await deleteTranscriptAPI(id);
    refreshTable();
  };

  const onClose = () => {
    setSelectedIdsArr([]);
    setOpen(false);
    refreshTable();
  };

  const columnDefs = useMeetingNotesColumnSidebar({
    onRetryTranscript,
    isRetryLoading,
    currentRetryId,
    onDeleteClick,
    selectedIdsArr,
    folderName,
    setSelectedIdsArr,
  });

  useEffect(() => {
    setSearchValue(tableRef);
  }, [tableRef.current]);

  const backButtonHandler = () => {
    if (setIsParamID) {
      setIsParamID({
        meetingId: 0,
        patientId: 0,
      });
    }
    // if (location.pathname.includes("transcription")) {
    //   if (setIsParamID) {
    //     setIsParamID({
    //       meetingId: 0,
    //       patientId: 0,
    //     });
    //   }
    //   navigate(-2);
    // }else if(backIsMic){
    //   if (setIsParamID) {
    //     setIsParamID({
    //       meetingId: 0,
    //       patientId: 0,
    //     });
    //   }
    //   setBackIsMic(false)
    //   navigate(-3);
    // }
    //  else {
    //   if (setIsParamID) {
    //     setIsParamID({
    //       meetingId: 0,
    //       patientId: 0,
    //     });
    //   }
    //   navigate(-1);
    // }
  };
  const onDeleteClickSidebar = () => {
    setOpen(true);
  };

  const deleteHandler = async () => {
    if (selectedIdsArr?.length) {
      await allDeleteTranscriptAPI(selectedIdsArr);
      setSelectedIdsArr([]);
      refreshTable();
    }
  };

  useEffect(() => {
    refreshTable();
  }, [meetingId]);

  return (
    <div>
      <div className="flex flex-wrap  justify-between h-full">
        <div className={"w-full xl:mt-5 h-full"}>
          <div className="flex justify-between items-center mt-4">
            <div className="flex justify-between">
              <div>
                <div
                  className="w-[20px] flex items-center"
                  onClick={() => {
                    backButtonHandler();
                  }}
                >
                  <ChevronLeft />
                </div>
              </div>
            </div>
            <div className="w-full mx-2">
              {folderName && (
                <div
                  className="text-[#111827] flex items-center bg-indigo-100 rounded-lg justify-center"
                  key={folderName}
                >
                  <p
                    className="font-medium w-full text-[14px] truncate text-indigo-700 bg-indigo-100 text-center py-2 px-2 rounded-lg whitespace-nowrap text-ellipsis max-w-[150px] overflow-hidden"
                    key={folderName}
                  >
                    {folderName}
                  </p>
                  {meetingId && <Edit3 className="mx-1 h-4" onClick={() => setIsOpen(!isOpen)} />}
                </div>
              )}
            </div>
            {isOpen && meetingId && (
              <AddMeetingForm
                setIsOpen={setIsOpen}
                folderID={+meetingId}
                refreshData={refreshTable}
              />
            )}
            <div className="flex lg:flex-wrap gap-4 items-center w-[20px] mx-2">
              <Mic
                onClick={() => {
                  setBackIsMic(true);
                  return navigate(
                    PRIVATE_NAVIGATION.MeetingFolder.recording(
                      String(meetingId)
                    )
                  );
                }}
                className="microphone w-5 h-5 cursor-pointer"
              />
            </div>
            <div className="flex lg:flex-wrap gap-4 items-center w-[20px] mx-2">
              <Upload
                onClick={() => {
                  setOpenUploadFile(!openUploadFile);
                }}
                className="microphone w-5 h-5 cursor-pointer"
              />
            </div>
          </div>
          <div className="content-table-data">
            <div
              className={`reactDataTable__DSD meetingListNotes h-full ${
                selectedIdsArr.length ? "data-check" : ""
              }`}
            >
              <DataTableReact
                setTableLength={null}
                ref={tableRef}
                columns={columnDefs}
                loader={isLoading}
                default_asc={false}
                default_sortColumn="Uploaded"
                getData={(params: {
                  page: number;
                  perPage: number;
                  sortField: string;
                  sortOrder: string;
                  search: string;
                }) => getTableData(params)}
              />
            </div>
          </div>
        </div>
      </div>
      <AlertModal
        visible={open}
        onClose={onClose}
        onCancel={onClose}
        onSubmit={() => {
          deleteHandler();
          setOpen(false);
        }}
        submitButtonText="Yes"
      >
        <p className="text-[18px] font-Biotif__SemiBold text-textDark text-center w-[300px] max-w-full mx-auto">
          {`Are you sure you want to delete ${
            (selectedIdsArr.length === 1 && "Recording") ||
            (selectedIdsArr.length > 1 && "Recordings")
          } ?`}
        </p>
      </AlertModal>

      {openUploadFile && (
        <UploadDraggableFile
          noteID={meetingId}
          note_type="meeting"
          refreshTable={() => refreshTable()}
          setOpenUploadFile={setOpenUploadFile}
          openUploadFile={openUploadFile}
          name={folderName ? folderName : ""}
        />
      )}

      <div className="p-3 border-t border-black/15">
        {selectedIdsArr?.length && hasNoteDeletePermission ? (
          <Button
            className="bg-white text-red-600 border border-red-600 w-48 hover:bg-red-400 hover:text-white hover:border-red-400"
            onClick={onDeleteClickSidebar}
          >
            Delete&nbsp;({selectedIdsArr?.length})
          </Button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
export default MeetingFolderAllNotes;
