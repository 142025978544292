import DataTableReact, { TableRefType } from "components/DataTable";
import { useCallback, useRef } from "react";
import { useGetAllPaymentHistoryAPI } from "../services";
import { debounce } from "lodash";
import { Search } from "react-feather";

import Breadcrumbs from "components/Breadcrumbs";
import { BREAD_CRUMB } from "constants/breadcrumbs.constant";
import useColRefInvoicesHistory from "../hooks/InvoicesHistoryColRef";

const SuperAdminInvoices = () => {
  const tableRef = useRef<TableRefType>(null);
  const { getAllPaymentHistoryApi, isLoading } = useGetAllPaymentHistoryAPI();
  const columnDefs = useColRefInvoicesHistory();

  const getTableData = async (params: {
    page: number;
    perPage: number;
    sortField: string;
    sortOrder: string;
    search: string;
  }) => {
    let tableData: any = { rowData: [], rowCount: 10 };
    const { data, error } = await getAllPaymentHistoryApi({
      params: {
        page: params.page,
        per_page: params.perPage,
        sort_by: params.sortField,
        sort_order: params.sortOrder,
        search: params.search,
        types: "invoice",
      },
    });
    if (data && !error) {
      tableData = {
        rowData: data?.data || [],
        rowCount: data?.count || 0,
      };
    }
    return tableData;
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    tableRef.current?.onChange?.(e);
  };

  const dataTable = useCallback(() => {
    return (
      <DataTableReact
        setTableLength={null}
        key={"superinvoice"}
        ref={tableRef}
        columns={columnDefs}
        loader={isLoading}
        getData={(params: {
          page: number;
          perPage: number;
          sortField: string;
          sortOrder: string;
          search: string;
        }) => getTableData(params)}
      />
    );
  }, []);

  return (
    <div>
      <Breadcrumbs path={BREAD_CRUMB.SuperAdminInvoices} />
      <div className="bg-bgWhiteSD rounded-[10px] shadow-raiseShadow p-[24px] sm:p-[12px] pb-[16px] mt-[20px] h-[calc(100dvh-130px)] overflow-y-hidden">
        <div className="flex md:flex-col items-center md:items-start justify-between ">
          <div className="form__group">
            <div className="field__wrapper mb-0">
              <div className="field__inner__wrapper field__has__icon icon__position__left">
                <input
                  className="input__DSD pl-11"
                  type="text"
                  placeholder="Search"
                  onChange={debounce(onSearchChange, 300)}
                />
                <div className="icon__wrapper">
                  <Search />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="reactDataTable__DSD invoices__wrapper">
          {dataTable()}
        </div>
      </div>
    </div>
  );
};

export default SuperAdminInvoices;
