// ** packages **
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactNode, useEffect, useState } from "react";
// ** components **
import InputField from "components/FormField/common/inputField";
import PageLoader from "components/Loaders/PageLoader";
import { Modal } from "components/Theme/Modal";

// ** services **
import {
  useAddMeetingFolderAPI,
  useGetMeetingFolderAPI,
  useUpdateMeetingAPI,
} from "../services/index";

// ** types **
import { AddMeetingFolderFormPropsType } from "../types/meeting.types";

// ** schema **
import { addMeetingSchema } from "../schema/Meeting.schema";
import { useNavigate } from "react-router-dom";

const AddMeetingForm = (props: AddMeetingFolderFormPropsType) => {
  const { setIsOpen, folderID, refreshData, getDataOnSuccess } = props;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<{
    name: string;
  }>({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(addMeetingSchema),
  });

  const { getMeetingFolderApi, isLoading: getPatientLoading } =
    useGetMeetingFolderAPI();
  const { addMeetingFolderApi, isLoading: addFolderLoading } =
    useAddMeetingFolderAPI();
  const { updateMeetingAPI, isLoading: updateLoading } = useUpdateMeetingAPI();
  const navigate = useNavigate();

  const resetData = async () => {
    if (folderID) {
      const { data } = await getMeetingFolderApi(folderID);
      const { name } = data;
      reset({
        name: name,
      });
    }
  };

  useEffect(() => {
    if (folderID) {
      resetData();
    }
  }, [folderID]);

  const onSubmit = handleSubmit(async (userData) => {
    const updatedMeetingFolderData = {
      name: userData?.name,
    };

    if (folderID) {
      const { data, error } = await updateMeetingAPI(
        updatedMeetingFolderData,
        folderID
      );
      if (data && !error) {
        if (setIsOpen) {
          setIsOpen(false);
        }
        if (refreshData) {
          refreshData();
        }
        if (getDataOnSuccess) {
          getDataOnSuccess(data);
        }
        navigate("", { state: { mode: "meeting", name: userData?.name } });
      }
    } else {
      const { data, error } = await addMeetingFolderApi(
        updatedMeetingFolderData
      );
      if (data && !error) {
        if (setIsOpen) {
          setIsOpen(false);
        }
        if (refreshData) {
          refreshData();
        }
        if (getDataOnSuccess) {
          getDataOnSuccess(data);
        }
      }
    }
  });
  const onClose = () => {
    if (setIsOpen) {
      setIsOpen(false);
    }
  };

  return (
    <Modal
      headerName={folderID ? "Edit folder" : "Create folder"}
      onSubmit={onSubmit}
      onClose={onClose}
      onCancel={onClose}
      isLoading={addFolderLoading || updateLoading}
    >
      {getPatientLoading ? (
        <PageLoader />
      ) : (
        <>
          <form onSubmit={onSubmit}>
            <InputField
              id="name"
              name="name"
              label="Name"
              register={register}
              required
              type="text"
              placeholder="Enter Folder Name"
              errors={errors.name}
              className="disabled:bg-slate-200"
            />
          </form>
        </>
      )}
    </Modal>
  );
};
export default AddMeetingForm;
