// ** packages **
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

// ** components **
import TableCellRender from "components/DataTable/components/TableCellRender";

// ** constants **
import { PRIVATE_NAVIGATION } from "constants/navigation.constant";
import { firstCharUpperCase, formatTimeAgo } from "../../../utils/index";
import { Mic } from "react-feather";
// ** helper **

const useMeetingColumn = () => {
  const navigate = useNavigate();
  const navigateToMeeting = (id: number | string) => {
    navigate(`${PRIVATE_NAVIGATION.MeetingFolder.detail.view(String(id))}`);
  };

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const handleResize = () => {
    if (
      (window.innerWidth > 1100 && windowSize < 1100) ||
      (window.innerWidth < 1100 && windowSize >= 1100)
    ) {
      setWindowSize(window.innerWidth);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowSize]);

  const columns = [
    {
      name: "Name",
      selector: (row: { name: string }) => row.name,
      sortable: true,
      sortField: "name",
      width: "70%",
      cell: (row: {
        name: string;
        id: number | string;
        created: {
          first_name: string;
          last_name: string;
          created_at: string;
        };
        updated: {
          first_name: string;
          last_name: string;
        };
        created_at: string;
        updated_at: string;
      }) => (
        <TableCellRender
          cellRowData={
            <>
              <div
                className="container mx-auto py-2 cursor-pointer"
                onClick={() => navigateToMeeting(row.id)}
              >
                <div className="truncate text-left ">
                  <h5 className="font-medium text-gray-900 whitespace-nowrap text-ellipsis max-w-[200px] overflow-hidden">
                    {row.name}
                  </h5>
                </div>
                <div className=" w-full max-w-full">
                  <div className=" text-gray-500 w-full max-w-full flex items-center flex-wrap text-[13px]">
                    <span className="max-w-[220px] overflow-hidden text-ellipsis whitespace-nowrap inline-block">
                      {" "}
                      {firstCharUpperCase(
                        row?.updated
                          ? row?.updated.first_name
                          : row?.created.first_name
                      ) +
                        " " +
                        firstCharUpperCase(
                          row?.updated
                            ? row?.updated.last_name
                            : row?.created.last_name
                        )}
                    </span>{" "}
                    &#x2022;{" "}
                    {formatTimeAgo(
                      row?.updated ? row?.updated_at : row?.created_at
                    )}
                  </div>
                </div>
              </div>
            </>
          }
        />
      ),
    },
    // {
    //   name: "Action",
    //   selector: (row: { id: string | number }) => row.id,
    //   sortable: false,
    //   width: "30%",
    //   cell: (row: { id: string | number }) => {
    //     const PatientAdd: {
    //       label: string;
    //       onClick: () => void;
    //     }[] = [];
    //     PatientAdd.push({
    //       label: "View",
    //       onClick: () => {
    //         navigate(
    //           `${PRIVATE_NAVIGATION.MeetingFolder.detail.view(String(row.id))}`
    //         );
    //       },
    //     });
    //     return (
    //       <div className="flex lg:flex-wrap gap-4">
    //         <span
    //           onClick={() => navigateToMeeting(row.id)}
    //           className="inline-flex whitespace-nowrap items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 cursor-pointer"
    //         >
    //           View
    //         </span>
    //       </div>
    //     );
    //   },
    // },
  ];

  const mobileColumns = [
    {
      name: "Name",
      selector: (row: { name: string }) => row.name,
      sortable: true,
      sortField: "name",
      // width: "40%",
      cell: (row: {
        name: string;
        id: number | string;
        created: {
          first_name: string;
          last_name: string;
          created_at: string;
        };
        updated: {
          first_name: string;
          last_name: string;
        };
        created_at: string;
        updated_at: string;
      }) => (
        <TableCellRender
          cellRowData={
            <>
              <div
                className="container mx-auto py-2 cursor-pointer"
                onClick={() => navigateToMeeting(row.id)}
              >
                <div className="truncate">
                  <div className="font-medium text-gray-900">{row.name}</div>
                </div>
                <div className="flex lg:flex-col items-center">
                  <div className="text-gray-500 text-[13px]">
                    Modified By{" "}
                    {firstCharUpperCase(
                      row?.updated
                        ? row?.updated.first_name
                        : row?.created.first_name
                    ) +
                      " " +
                      firstCharUpperCase(
                        row?.updated
                          ? row?.updated.last_name
                          : row?.created.last_name
                      )}{" "}
                    &#x2022;{" "}
                    {formatTimeAgo(
                      row?.updated ? row?.updated_at : row?.created_at
                    )}
                  </div>
                </div>
              </div>
            </>
          }
        />
      ),
    },
    // {
    //   name: "Action",
    //   selector: (row: { id: string | number }) => row.id,
    //   sortable: false,
    //   width: "30%",
    //   cell: (row: { id: string | number }) => {
    //     const PatientAdd: {
    //       label: string;
    //       onClick: () => void;
    //     }[] = [];
    //     PatientAdd.push({
    //       label: "View",
    //       onClick: () => {
    //         navigate(
    //           `${PRIVATE_NAVIGATION.MeetingFolder.detail.view(String(row.id))}`
    //         );
    //       },
    //     });
    //     return (
    //       <div className="flex lg:flex-wrap gap-4">
    //         <span
    //           onClick={() => navigateToMeeting(row.id)}
    //           className="inline-flex whitespace-nowrap items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 cursor-pointer"
    //         >
    //           View
    //         </span>
    //       </div>
    //     );
    //   },
    // },
  ];
  const columnDefs = useMemo(
    () => (windowSize > 1100 ? columns : mobileColumns),
    [windowSize]
  );
  return columnDefs;
};

export default useMeetingColumn;
