// ** packages **
import React, { useEffect, useMemo, useState } from "react";
import { MoreVertical } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";
// ** components **
import TableCellRender from "components/DataTable/components/TableCellRender";
import TippyDropdown from "components/TippyDropDown";
import TranscriptionStatus from "components/Badge/TranscriptionStatus";

// ** constants **
import { PRIVATE_NAVIGATION } from "constants/navigation.constant";
// ** helper **
import { renderOptions } from "components/TippyDropDown/helper";
import DateFormat from "components/DateFormat";
import { HoursMinSecFormat, firstCharUpperCase } from "utils";
import TranscriptionBtn from "components/GroupBtn/TranscriptionBtn";
import {
  useDeleteTranscriptAPI,
  useRetryTranscriptAPI,
} from "modules/Patient/sub-modules/Transcription/services";
import { TableRefType } from "components/DataTable";
import usePermission from "hooks/usePermission";
import { PERMISSIONS } from "constants/Permissions.constant";
import SvgIcon from "components/Icon/SvgIcon";
import Button from "components/Theme/Button";
import CheckBoxField from "components/FormField/common/CheckBoxField";

type useNotesColumnPropsType = {
  currentNote: string;
  tableRef: React.RefObject<TableRefType>;
  onDeleteClick: (id: number | string) => void;
  selectedIdsArr: number[];
  setSelectedIdsArr: React.Dispatch<React.SetStateAction<number[]>>;
};

const useNotesColumnSidebar = (props: useNotesColumnPropsType) => {
  const {
    currentNote,
    tableRef,
    onDeleteClick,
    selectedIdsArr,
    setSelectedIdsArr,
  } = props;
  const navigate = useNavigate();
  const { hasPermission } = usePermission();
  const hasNoteDeletePermission = hasPermission(PERMISSIONS.NOTES.DELETE);

  const navigateToTranscript = (
    id: string | number,
    OpenSummary: boolean = false,
    mode: string = "patient"
  ) => {
    navigate(PRIVATE_NAVIGATION.transcription.detail.view(String(id)), {
      state: {
        mode: mode,
        tab: {
          summary: OpenSummary,
          transcript: !OpenSummary,
          share: false,
          playbook: false,
        },
        navHighlighter: mode,
      },
    });
  };

  const { retryTranscriptAPI, isLoading: isRetryLoading } =
    useRetryTranscriptAPI();

  const onRetryTranscript = async (id: number | undefined) => {
    if (id) {
      await retryTranscriptAPI(id);
      tableRef?.current?.refreshData();
    }
  };

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const handleResize = () => {
    if (
      (window.innerWidth > 1300 && windowSize < 1300) ||
      (window.innerWidth < 1300 && windowSize >= 1300)
    ) {
      setWindowSize(window.innerWidth);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowSize]);

  const handleCheckboxChange = (id: number, isChecked: boolean) => {
    setSelectedIdsArr((prevSelectedIds: any = []) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter(
          (selectedId: number) => selectedId !== id
        );
      } else {
        if (isChecked && id) {
          return [...prevSelectedIds, id];
        }
        return prevSelectedIds;
      }
    });
  };

  const columnDefs = useMemo(
    () => [
      {
        // name: "Select",
        selector: (row: { id: string | number }) => row.id,
        sortable: false,
        width: "50px",
        cell: (row: {
          id: string | number;
          status: string;
          note_type: string;
        }) => (
          <TableCellRender
            cellRowData={
              <div className="custom__checkbox__SD without__label">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    handleCheckboxChange(+row.id, e.target.checked);
                  }}
                  checked={selectedIdsArr?.includes(+row.id)}
                />
                <label className="label__SD"></label>
              </div>
            }
          />
        ),
      },
      {
        name: "Name",
        selector: (row: { file_name: string }) => row?.file_name,
        sortable: true,
        sortField: "file_name",
        width: "84%",
        cell: (row: {
          file_name: string;
          created_at: string;
          created: { first_name: string; last_name: string };
          file_duration: string;
          is_active: boolean;
          // status: string | null;
          id: number | string;
          status: string;
          note_type: string;
        }) => (
          <TableCellRender
            cellRowData={
              <>
                <div
                  className="py-2 cursor-pointer flex items-center justify-between"
                  onClick={() => navigateToTranscript(row?.id, true)}
                >
                  <div>
                    <div className="flex gap-2 items-center">
                      <span className="font-medium text-gray-900 truncate max-w-[90px] 4xl:max-w-[50px]">
                        {row.file_name}
                      </span>
                      <span className="flex gap-2 text-gray-500">
                        {" "}
                        &#x2022;{" "}
                      </span>
                      {row?.status && (
                        <div className="checkmark">
                          <TranscriptionStatus status={row?.status} />
                        </div>
                      )}
                    </div>
                    <div className="text-left">
                      <div className="flex gap-2 items-center text-gray-500 justify-start">
                        {row?.created && (
                          <>
                            <span className="text-[13px]">
                              {firstCharUpperCase(row?.created?.first_name)}{" "}
                              {firstCharUpperCase(row?.created?.last_name)}
                              &#x2022;
                              {row.created_at && (
                                <DateFormat
                                  date={row.created_at}
                                  format="dd MMM yyyy"
                                />
                              )}
                            </span>
                          </>
                        )}
                        {/* <span className="text-[13px]"> */}
                        {/* {row?.created &&
                            `${firstCharUpperCase(
                              row?.created?.first_name
                            )} ${firstCharUpperCase(
                              row?.created?.last_name
                            )}`}{" "} */}
                        {/* &#x2022;{" "}
                          {row.created_at && (
                            <DateFormat
                              date={row.created_at}
                              format="dd MMM yyyy"
                            />
                          )} */}
                        {/* &#x2022;{" "}
                        {row?.created &&
                          `${HoursMinSecFormat(parseInt(row.file_duration))}`} */}
                        {/* </span> */}
                      </div>
                    </div>
                  </div>
                  <TranscriptionBtn
                    onDelete={onDeleteClick}
                    hasNoteDeletePermission={hasNoteDeletePermission}
                    id={row?.id}
                    status={row?.status}
                    onClick={navigateToTranscript}
                    onClickFailed={onRetryTranscript}
                    isRetryLoading={isRetryLoading}
                    currentRetryId={row?.id}
                    noteType={row?.note_type}
                  />
                </div>
              </>
            }
          />
        ),
      },
      // {
      //   name: "Action",
      //   selector: (row: { id: string | number }) => row.id,
      //   sortable: false,
      //   width: "50px",
      //   cell: (row: {
      //     id: string | number;
      //     status: string;
      //     note_type: string;
      //   }) => (
      //     <TableCellRender
      //       cellRowData={
      //         <TranscriptionBtn
      //           onDelete={onDeleteClick}
      //           hasNoteDeletePermission={hasNoteDeletePermission}
      //           id={row?.id}
      //           status={row?.status}
      //           onClick={navigateToTranscript}
      //           onClickFailed={onRetryTranscript}
      //           isRetryLoading={isRetryLoading}
      //           currentRetryId={row?.id}
      //           noteType={row?.note_type}
      //         />
      //       }
      //     />
      //   ),
      // },
    ],
    [currentNote, windowSize, selectedIdsArr]
  );

  return columnDefs;
};

export default useNotesColumnSidebar;
