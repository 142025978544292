import {
  Check,
  Trash as Delete,
  Edit,
  Edit2,
  Edit3,
  Info,
  Mic,
} from "react-feather";
// ** types **
import {
  RecordingPropsType,
  noteOptionsTypes,
  tipTapNoteType,
} from "../types/recording.types";
// ** Packages **
import React, { useEffect, useMemo, useRef, useState } from "react";

import AlertModal from "components/modal/AlertModal";
import DraggableFileInput from "components/DraggableFileInput";
import { Editor } from "@tiptap/core";
import InputField from "components/FormField/common/inputField";
import Note from "components/Note";
// ** constants **
import { PRIVATE_NAVIGATION } from "constants/navigation.constant";
import PageLoader from "components/Loaders/PageLoader";
// ** components **
import RecordingButtonPage from "./RecordingButtonPage";
import RouteChangeConformationModal from "components/RouteChangeModal/RouteChangeInfoModal";
import SelectField from "components/FormField/common/SelectField";
import Stopwatch from "components/CountDown";
import SvgIcon, {
  PlaybookNewIcon,
  PlaybookTemplateIcon,
} from "components/Icon/SvgIcon";
import TippyDropdown from "components/TippyDropDown";
// ** services **
import { useAddAudioRecordingAPI } from "../Services";
// ** hooks **
import useAudioRecorder from "../hooks/useAudioRecorder";
import { useNavigate } from "react-router-dom";
import { allowed_mimetype } from "../constant";
import Tab from "components/Tab";
import { tabsType } from "../../Transcription/types/transcription.types";
import {
  useAddTemplatePlaybookAPI,
  usePlaybookTemplatesList,
  useUpdateTemplatePlaybookAPI,
} from "modules/Admin/sub-module/AdminPrompt/services";
import SlideOverDialogTailWindUI from "components/Siderover/SlideOverDialogTailWindUI";
import Button from "components/Theme/Button";
import MultiSelect, {
  MultiSelectOptionTypes,
} from "components/FormField/common/MultiSelect";
import { firstCharUpperCase } from "utils";
import { useGetAllNoteTypeAPI } from "modules/Setting/sub-modules/PersonalSetting/services";
import usePermission from "hooks/usePermission";
import { PERMISSIONS } from "constants/Permissions.constant";

const AudioRecorderPage = (props: RecordingPropsType) => {
  // ** props **
  const { refId, typeOfRec } = props;
  const navigate = useNavigate();
  // ** api **
  const { addAudioRecordingApi, isLoading } = useAddAudioRecordingAPI();
  // ** state **
  const [inputDevice, setInputDevice] = useState<any>();
  const [error, setErrors] = useState("");
  const [selectedInputDevice, setSelectedInputDevice] = useState<any>(
    inputDevice?.[0]
  );
  const [file, setFile] = useState<FileList | undefined>();
  const [fileForSubmit, setFileForSubmit] = useState<FileList | undefined>();

  const [microPhoneNotDetect, setMicroPhoneNotDetect] = useState(false);
  useMemo(() => {
    setSelectedInputDevice(inputDevice?.[0]);
  }, [inputDevice]);
  const [microPhonePermissionError, setMicroPhonePermissionError] =
    useState(false);
  const [open, setOpen] = useState<{
    recordingButton: boolean;
    transcript: boolean;
    defaultPage: boolean;
    recordingStart: boolean;
    microPhoneErrorAlert: boolean;
  }>({
    recordingButton: false,
    transcript: false,
    defaultPage: false,
    recordingStart: true,
    microPhoneErrorAlert: false,
  });
  const [fileName, setFileName] = useState<string>("Untitled");
  const [description, setDescription] = useState<string | null | undefined>("");
  const [playbookType, setPlaybookType] = useState<
    MultiSelectOptionTypes[] | any
  >([]);
  const [notes, setNotes] = useState<tipTapNoteType[]>([]);
  const [currentNote, setCurrentNote] = useState<tipTapNoteType>();
  const [isCurrentlyEdit, setIsCurrentlyEdit] = useState<boolean>(false);
  const [isNoteOpen, setIsNoteOpen] = useState<boolean>(false);
  const publishRef = useRef<HTMLButtonElement>(null);
  const [isDeleteAlert, setIsDeleteAlert] = useState<boolean>(false);
  const [fileNameEditStatus, setFileNameEditStatus] = useState<boolean>(false);
  const [isSaveDefault, setIsSaveDefault] = useState<boolean>(false);
  const [saveOption, setSaveOption] = useState<MultiSelectOptionTypes | any>(
    []
  );
  const [showError, setShowError] = useState(false);
  const { getNoteTypesApi } = useGetAllNoteTypeAPI();
  const { hasPermission } = usePermission();
  const playbookCreatePermission = hasPermission(PERMISSIONS.PLAYBOOK.CREATE);
  const playbookReadPermission = hasPermission(PERMISSIONS.PLAYBOOK.READ);
  const noteContent = useRef<string>("");

  const { addTemplatePlaybookAPI } = useAddTemplatePlaybookAPI();
  const { updateTemplatePlaybookAPI } = useUpdateTemplatePlaybookAPI();
  useEffect(() => {
    setOpen((prev) => {
      return { ...prev, recordingStart: true };
    });
  }, [refId]);

  const noteOption = typeOfRec ?? "content";
  const noteOptions: noteOptionsTypes = {
    defaultSelected: typeOfRec === "patient" ? "patient" : noteOption,
    allOptions:
      typeOfRec === "patient"
        ? [{ icon: "User", optionValue: "patient", tooltip: "Patient" }]
        : [
            { icon: "Book", optionValue: "content", tooltip: "Content" },
            { icon: "MessageSquare", optionValue: "voice", tooltip: "Voice" },
            {
              icon: "MessageSquare",
              optionValue: "meeting",
              tooltip: "Meeting",
            },
          ],
  };

  const {
    isTestingMicrophone,
    isRecording,
    setIsRecording,
    isPaused,
    startTestingMicrophone,
    stopTestingMicrophone,
    audioRecording,
    setAudioRecording,
    startRecording,
    stopRecording,
    restartRecording,
    deleteRecording,
    pauseResumeRecording,
    microphoneInputDevice,
    setMicrophoneInputDevice,
    visualizationCanvasRef,
  } = useAudioRecorder({ setOpen: setOpen });

  useEffect(() => {
    if (isTestingMicrophone) {
      startTestingMicrophone();
    }
  }, [microphoneInputDevice]);

  function getCurrentTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    const formattedTime = `${year}${month}${day}-${hours}${minutes}${seconds}`;
    return formattedTime;
  }

  const AudioRecord = async () => {
    if (audioRecording || fileForSubmit) {
      const formData = new FormData();
      const newfileName =
        "DSD_" + getCurrentTime() + "audio" + Math.floor(Math.random() * 20);
      if (audioRecording) {
        formData.append(
          "media_url",
          audioRecording,
          `${
            fileName !== "Untitled" && fileName?.trim()?.length > 0
              ? fileName
              : newfileName
          }.webm`
        );
      }
      formData.append("model", "assemblyai");
      formData.append("note_type", noteOptions.defaultSelected);
      if (refId) {
        formData.append("patient_id", String(refId));
      }
      if (fileName.trim() && fileName !== "Untitled") {
        formData.append("file_name", fileName);
      }

      if (fileForSubmit) {
        formData.append(
          "media_url",
          fileForSubmit?.[0],
          fileForSubmit?.[0].name
        );
        setFileForSubmit(undefined);
      }
      if (notes.length > 0) {
        let count = 0;
        notes.forEach((note, i) => {
          if (!note?.title?.trim()?.length) {
            count += 1;
            note.title = `Untitled ${count}`;
          }
        });
        formData.append("notes", JSON.stringify(notes));
      }
      const { data, error } = await addAudioRecordingApi(formData);
      if (data && !error) {
        setOpen({
          recordingButton: false,
          transcript: false,
          defaultPage: false,
          recordingStart: false,
          microPhoneErrorAlert: false,
        });
        if (data?.[0]?.id) {
          navigate(
            PRIVATE_NAVIGATION.transcription.detail.view(String(data?.[0]?.id)),
            {
              state: {
                mode: typeOfRec,
                tab: {
                  summary: false,
                  transcript: true,
                  share: false,
                  playbook: true,
                },
                navHighlighter: typeOfRec,
                id: refId,
              },
            }
          );
        } else if (refId) {
          if (noteOption === "patient") {
            navigate(PRIVATE_NAVIGATION.patient.detail.view(String(refId)));
          } else {
            navigate(
              PRIVATE_NAVIGATION.MeetingFolder.detail.view(String(refId))
            );
          }
        } else {
          navigate(PRIVATE_NAVIGATION.dashboard.view);
        }
        setAudioRecording(undefined);
        setNotes([]);
        setCurrentNote(undefined);
        setIsNoteOpen(false);
        setIsCurrentlyEdit(false);
      }
    }
  };

  const onSave = () => {
    if (microphoneInputDevice) {
      if (!error) {
        startRecording();
      }
    } else {
      setErrors("Select your Microphone ");
    }
  };
  const { getPlaybookListApi, isLoading: TemplatePlaybookLoading } =
    usePlaybookTemplatesList();
  const getTemplatePlaybook = async () => {
    const noteType: string[] | any = [typeOfRec ?? null];
    const { data, error } = await getPlaybookListApi({}, true, noteType);
    if (data && data.length > 0) {
      const tempData = [...data];
      setNotes(tempData);
    }
  };

  const onSaveTipTapNote = (editor: Editor | null) => {
    if (editor) {
      const newCurrentNote = Object.assign({}, currentNote);
      newCurrentNote.content = editor?.getHTML();
      noteContent.current = editor?.getHTML();
      newCurrentNote.description = description;
      newCurrentNote.type = playbookType;
      const getIndex = notes.findIndex((note) => note?.id === currentNote?.id);
      const newNotes = Object.assign([], notes);
      newNotes.splice(getIndex, 1, newCurrentNote);
      setNotes(newNotes);
      setIsCurrentlyEdit(false);
      setCurrentNote(newCurrentNote);
      setDescription(newCurrentNote.description);
      setPlaybookType(newCurrentNote.type);
    }
  };

  useEffect(() => {
    playbookReadPermission && getTemplatePlaybook();
    if (isRecording || isTestingMicrophone || isLoading) {
      const beforeUnloadHandler = (event: BeforeUnloadEvent) => {
        const confirmationMessage = "Your changes may not be saved.";
        (event || window.event).returnValue = confirmationMessage;
        deleteRecording();
        return confirmationMessage;
      };

      window.addEventListener("beforeunload", beforeUnloadHandler);

      return () => {
        if (isTestingMicrophone) {
          stopTestingMicrophone();
        }
        window.removeEventListener("beforeunload", beforeUnloadHandler);
      };
    }
  }, [isRecording, isTestingMicrophone, isLoading]);

  function getLocalStream() {
    navigator?.mediaDevices
      ?.getUserMedia({ video: false, audio: true })
      .then((stream) => {
        setMicroPhonePermissionError(false);
        navigator.mediaDevices.enumerateDevices().then((devices) => {
          if (devices) {
            const filteredDevices = devices
              .filter((device) => device.kind === "audioinput")
              .map(({ label, deviceId }) => ({
                label: label,
                value: deviceId,
              }));
            if (filteredDevices) {
              setMicrophoneInputDevice(filteredDevices[0].value);
              setInputDevice(filteredDevices);
            }
          }
        });
      })
      .catch((err) => {
        setMicroPhonePermissionError(true);
        if (err.message === "Requested device not found") {
          setMicroPhoneNotDetect(true);
          setOpen({
            recordingButton: false,
            transcript: false,
            defaultPage: false,
            recordingStart: false,
            microPhoneErrorAlert: true,
          });
        } else {
          setOpen({
            recordingButton: false,
            transcript: false,
            defaultPage: false,
            microPhoneErrorAlert: true,
            recordingStart: false,
          });
          setMicroPhonePermissionError(true);
        }
      });
  }

  const getPromptTypes = async () => {
    if (playbookCreatePermission) {
      const { data, error } = await getNoteTypesApi();
      if (!error && data) {
        const dataT: string[] = data;
        const tempData = dataT.map((item) => {
          return { title: `Save as ${firstCharUpperCase(item)}`, value: item };
        });
        if (tempData && saveOption) {
          tempData.push(...saveOption);
          setSaveOption(tempData);
        }
      }
    }
  };

  const onDefaultSave = async (isTemplate: boolean | null | undefined) => {
    setIsSaveDefault(false);
    if (publishRef) {
      publishRef?.current?.click();
    }
    const newCurrentNote = Object.assign({}, currentNote);
    newCurrentNote.description = description;
    newCurrentNote.type = playbookType;

    newCurrentNote["content"] = noteContent?.current || "";

    if (newCurrentNote?.id && isTemplate) {
      await updateTemplatePlaybookAPI(newCurrentNote, newCurrentNote.id);
    } else {
      newCurrentNote.isTemplate = true;
      await addTemplatePlaybookAPI(newCurrentNote);
    }
    const getIndex = notes.findIndex((note) => note?.id === currentNote?.id);
    const newNotes = Object.assign([], notes);
    newNotes.splice(getIndex, 1, newCurrentNote);
    setNotes(newNotes);
    setCurrentNote(newCurrentNote);
    setDescription(newCurrentNote.description);
    setPlaybookType(newCurrentNote.type);
  };

  useEffect(() => {
    getLocalStream();
    getPromptTypes();
  }, []);

  const AlertModalStartRecording = () => {
    if (!microPhonePermissionError) {
      if (fileName !== "Untitled") {
        setFileName("Untitled");
      }
      setOpen({
        recordingButton: false,
        transcript: false,
        defaultPage: false,
        microPhoneErrorAlert: false,
        recordingStart: true,
      });
    } else {
      microPhoneOffAlert();
    }
  };
  useEffect(() => {
    (audioRecording || fileForSubmit) && AudioRecord();
  }, [audioRecording, fileForSubmit]);

  const onClose = () => {
    setOpen({
      recordingButton: false,
      transcript: false,
      defaultPage: true,
      recordingStart: false,
      microPhoneErrorAlert: false,
    });
    if (refId) {
      if (noteOption === "patient") {
        navigate(PRIVATE_NAVIGATION.patient.detail.view(String(refId)), {
          state: { note_type: "patient" },
        });
      } else {
        navigate(PRIVATE_NAVIGATION.MeetingFolder.detail.view(String(refId)), {
          state: { note_type: "meeting" },
        });
      }
    } else if (noteOption === "content") {
      navigate(PRIVATE_NAVIGATION.dashboard.view, {
        state: { note_type: "content" },
      });
    } else if (noteOption === "voice") {
      navigate(PRIVATE_NAVIGATION.dashboard.view, {
        state: { note_type: "voice" },
      });
    }
  };
  const InputDeviceID = (e: any) => {
    setErrors("");
    setMicrophoneInputDevice(e?.value);
    setSelectedInputDevice(e);
  };
  const microPhoneOffAlert = () => {
    setOpen({
      recordingButton: false,
      transcript: false,
      defaultPage: false,
      recordingStart: false,
      microPhoneErrorAlert: true,
    });
  };
  const handleFile = (e: any) => {
    setFileForSubmit(e);
  };

  const onNoteTippyClick = (
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>> | undefined,
    id: number | string | null
  ) => {
    if (currentNote?.id && publishRef) {
      publishRef?.current?.click();
    }
    if (!id) {
      setDescription("");
      setPlaybookType([]);
      let notesLen = 1;
      if (notes.length > 0) {
        if (!notes[notes?.length - 1].isTemplate) {
          notesLen = notes[notes?.length - 1]?.id + 1;
        }
      }
      setNotes((prevState) => [
        ...prevState,
        {
          id: notesLen,
          title: `Untitled ${notesLen}`,
          content: "",
          isTemplate: false,
          description: "",
          type: [],
        },
      ]);
      setCurrentNote({
        id: notesLen,
        title: `Untitled ${notesLen}`,
        content: "",
        isTemplate: false,
        description: "",
        type: [],
      });
      if (setIsOpen) {
        setIsOpen(true);
        setShowPlayBook(false);
      }
    }
    if (!isNoteOpen) {
      setIsNoteOpen(true);
    }
  };

  const onNoteDelete = (id: number | string) => {
    const deletedIndex = notes.findIndex((note) => note.id === id);
    const newNotes = Object.assign([], notes);
    newNotes.splice(deletedIndex, 1);
    if (notes[deletedIndex]?.id === currentNote?.id) {
      setCurrentNote(undefined);
      setIsNoteOpen(false);
    }
    setNotes(newNotes);
  };

  const onNoteChange = (
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>> | undefined,
    id: number | string | null
  ) => {
    const selectedNote = notes.find((note) => note.id === id);
    if (publishRef) {
      publishRef?.current?.click();
    }
    setCurrentNote(selectedNote);
    setDescription(selectedNote?.description);
    setPlaybookType(selectedNote?.type);
    if (setIsOpen) {
      setIsOpen(true);
      setShowPlayBook(false);
    }
    if (!isNoteOpen) {
      setIsNoteOpen(true);
    }
  };

  const getCreateNoteTippy = (
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>> | undefined
  ) => {
    return (
      <div className="tippy__dropdown__ul">
        <div className="container mx-auto px-4 md:px-2">
          <div className="flex flex-wrap gap-4">
            {notes.length > 0 ? (
              notes?.map((note, index) => (
                <div
                  className="flex justify-between border rounded-lg hover:bg-indigo-100"
                  key={`${index}`}
                >
                  <div
                    className="flex items-center gap-3 w-full p-3 cursor-pointer"
                    onClick={() => onNoteChange(setIsOpen, note?.id)}
                  >
                    {note?.isTemplate ? (
                      <PlaybookTemplateIcon
                        iconcolor={"#6558F5"}
                        className="w-fit"
                      />
                    ) : (
                      <PlaybookNewIcon className="w-fit" />
                    )}
                    <div className="justify-center content-evenly">
                      <div>{note?.title}</div>
                      {note?.description && (
                        <div className="ml-2 text-sm">{note?.description}</div>
                      )}
                    </div>
                  </div>
                  {!note?.isTemplate && (
                    <div className="items-center flex p-2 cursor-pointer rounded hover:bg-blue-200 z-[999]">
                      <Delete
                        className="!h-[20px] w-[20px]"
                        onClick={() => onNoteDelete(note?.id)}
                      />
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div>No Playbook</div>
            )}
          </div>
          {playbookCreatePermission && (
            <>
              <hr className="mt-3" />
              <div
                className="text-sm text-[#6558F5] cursor-pointer p-2"
                onClick={() => onNoteTippyClick(setIsOpen, null)}
              >
                {notes.length > 0 && "or"} Create new recording script
              </div>
            </>
          )}
        </div>
      </div>
    );
  };
  const noteTippy = useMemo(() => getCreateNoteTippy, [currentNote, notes]);

  const onChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (currentNote) {
      setPlaybookType(currentNote.type);
      setCurrentNote({
        title: e?.target?.value,
        content: currentNote?.content,
        id: currentNote?.id,
        isTemplate: currentNote.isTemplate,
        description: currentNote.description,
        type: currentNote.type,
      });
      setDescription(description ? description : currentNote?.description);
    }
  };

  const changeNoteName = () => {
    if (currentNote) {
      const ind = notes.findIndex((note) => note?.id === currentNote?.id);
      const newNotes = Object.assign([], notes);
      newNotes.splice(ind, 1, currentNote);
      setNotes(newNotes);
      setIsCurrentlyEdit(false);
    }
  };

  const headerOfTipTapNote = (
    setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    return (
      <>
        {currentNote?.id && (
          <div className="flex items-center gap-2">
            {isCurrentlyEdit ? (
              <InputField
                name="currentNote"
                value={currentNote?.title}
                onChange={(e) => onChangeTitle(e)}
                disabled={!isCurrentlyEdit}
                onKeyDown={(e) => {
                  e?.key === "Enter" && changeNoteName();
                  setIsDirty && setIsDirty(true);
                }}
                autoComplete="off"
                className="input__DSD flex"
                fieldWrapperClassName="!m-0"
              />
            ) : (
              <span>{currentNote?.title}</span>
            )}
            {!isCurrentlyEdit && (
              <Edit3
                className="size-4 cursor-pointer"
                onClick={() => {
                  setIsCurrentlyEdit(true);
                }}
              />
            )}
          </div>
        )}
      </>
    );
  };

  const tabs: tabsType[] = [
    { name: "Recording", value: "recording", disabled: false },
    { name: "Transcription", value: "transcription", disabled: true },
    {
      name: "Export & Share",
      value: "share",
      disabled: true,
    },
    {
      name: "PlayBook",
      value: "playbook",
      disabled: false,
    },
  ];
  const [showPlayBook, setShowPlayBook] = useState(false);
  const onChangeTab = (tabName: string) => {
    if (tabName === "playbook") {
      setShowPlayBook(!showPlayBook);
    }
  };

  const fileEdit = () => {
    return (
      <>
        <div className="max-w-[20vw] lg:max-w-[30vw] md:max-w-[50vw] sm:max-w-none right-0 editname">
          {fileNameEditStatus ? (
            <div className="flex items-center mr-2 min-w-[calc(100%_-_115px)] relative">
              <input
                className="input__DSD py-3 text-xs pr-7"
                type="text"
                placeholder="Enter File Name"
                id="fileName"
                autoComplete="off"
                defaultValue={fileName || ""}
                onChange={(e) => setFileName(e.target.value ?? "")}
                onKeyDown={(e) => {
                  e?.key === "Enter" && setFileNameEditStatus(false);
                }}
                disabled={isLoading}
                maxLength={30}
              />
              <Check
                className="cursor-pointer absolute right-2 w-4 h-4"
                onClick={() => setFileNameEditStatus(false)}
              />
            </div>
          ) : (
            <div className="flex items-center mr-2">
              <div className="py-3 text-sm break-all font-medium max-w-[350px] overflow-hidden text-ellipsis whitespace-nowrap">
                {fileName || ""}
              </div>
              <Edit3
                className="mx-2 w-4 h-4 cursor-pointer"
                onClick={() => setFileNameEditStatus(true)}
              />
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <div className="rounded-[10px]  px-3">
      <div className="w-full">
        <div className="flex sm:flex-col items-center sm:items-start mb-5 cursor-pointer">
          <div className="flex items-center sm:mb-4">
            <div
              className={` relative after:absolute after:h-8 after:w-[2px] after:bg-[#788896] after:top-1/2 after:-translate-y-1/2 after:right-0 mr-3 sm:after:hidden sm:mr-0 3xl:after:hidden`}
            >
              {fileEdit()}
            </div>
          </div>

          <Tab
            tabs={tabs}
            currentTabValue={"recording"}
            onChangeTab={onChangeTab}
          />
        </div>
      </div>
      <SlideOverDialogTailWindUI
        open={showPlayBook}
        handleClose={() => setShowPlayBook(false)}
        title="Playbook"
        DialogPanelClass="pointer-events-auto w-screen max-w-[60vw] md2:max-w-full"
      >
        {playbookReadPermission && (
          <div className="rounded-[10px] shadow-md p-[24px] md2:p-[8px] pb-[16px] ">
            {getCreateNoteTippy(setIsNoteOpen)}
          </div>
        )}
      </SlideOverDialogTailWindUI>
      <div>
        <div
          className={`${
            open?.recordingButton ? "block" : "flex flex-wrap"
          }  items-start -mx-[15px] py-[15px]`}
        >
          <div
            className={`w-[30%] 3xl:w-[40%] xl:w-full px-[15px] ${
              open?.recordingButton ? "hidden" : ""
            }`}
          >
            <div className="flex justify-center">
              <div className="w-full">
                {(open.defaultPage && !isLoading) ||
                (!isRecording && !isLoading) ? (
                  <div className=" ">
                    <div className={`flex items-center`}>
                      {!microPhonePermissionError ? (
                        <>
                          {/* <div
                        onClick={
                          isTestingMicrophone
                            ? stopTestingMicrophone
                            : startTestingMicrophone
                        }
                        className="mb-4 w-8 h-11 flex items-center rounded-full py-[7px] text-center cursor-pointer"
                      >
                        {isTestingMicrophone ? (
                          <SvgIcon
                            iconName="MicrophoneOffIcon"
                            className="w-7 h-7"
                          />
                        ) : (
                          <SvgIcon
                            iconName="MicrophoneIcon"
                            className="w-7 h-6"
                          />
                        )}
                      </div> */}
                        </>
                      ) : (
                        <div className="cursor-pointer text-black mb-4 microphone-container not-accessible min-w-[40px] bg-red-100 w-[40px] h-[40px] flex items-center justify-center rounded-full border-2 border-rose-400 mr-2 relative">
                          <span className="absolute text-white -top-[2px] -right-1 w-[14px] h-[14px] flex items-center justify-center bg-red-400 rounded-full text-[10px]">
                            !
                          </span>
                          <div onClick={microPhoneOffAlert}>
                            <SvgIcon
                              iconName="MicrophoneIcon"
                              className="w-7 h-7"
                            />
                          </div>
                          {/* <Mic
                            onClick={microPhoneOffAlert}
                            className="microphone w-5 h-5"
                          /> */}
                        </div>
                      )}

                      {/* <SelectField
                      wrapperClass={` ${!microPhonePermissionError
                        ? ""
                        : "opacity-50 pointer-events-none"
                        } `}
                      value={selectedInputDevice}
                      name="defaultValSelect"
                      options={inputDevice}
                      onChange={InputDeviceID}
                    /> */}
                    </div>
                    <div className="w-full text-center my-7">
                      <Button
                        className="hover:text-white py-4 min-w-[62%] border border-indigo-700 bg-indigo-700 text-white"
                        disabled={file ? true : false}
                        onClick={
                          fileName?.toLocaleLowerCase() === "untitled"
                            ? AlertModalStartRecording
                            : onSave
                        } // when you wanted to start recording without alert
                        // onClick={AlertModalStartRecording}
                      >
                        <span className="flex items-center select-none">
                          <Mic className="mr-1 w-5 h-5" /> New Recording
                        </span>
                      </Button>
                    </div>
                    <div className="h-1 w-3/4 bg-[#788889] mx-auto "></div>
                  </div>
                ) : (
                  <></>
                )}
                {isTestingMicrophone || isRecording ? (
                  <RouteChangeConformationModal
                    saveFunction={deleteRecording}
                    isDirtyCondition={isRecording || isTestingMicrophone}
                  />
                ) : (
                  <></>
                )}
                <AlertModal
                  visible={open.recordingStart}
                  onClose={onClose}
                  onCancel={onClose}
                  onSubmit={onSave}
                  submitButtonText="Yes"
                >
                  <p className="text-center mb-3">
                    Select your microphone for recording
                  </p>
                  <InputField
                    name="file_name"
                    id="file_name"
                    type="text"
                    label="Note title"
                    onChange={(e) => setFileName(e?.target?.value ?? "")}
                    value={fileName}
                    maxLength={30}
                  />
                  <SelectField
                    className="mx-auto"
                    name="defaultValSelect"
                    options={inputDevice}
                    onChange={InputDeviceID}
                    value={selectedInputDevice}
                  />
                  <p className="text-[18px] font-Biotif__SemiBold text-textDark text-center w-[300px] max-w-full mx-auto">
                    {`Do you want to start Recording ?`}
                  </p>
                  <p className="text-red-500">{error ?? ""}</p>
                </AlertModal>
                <AlertModal
                  visible={open.microPhoneErrorAlert}
                  onClose={onClose}
                  showFooter={false}
                  showCrossIcon
                >
                  <>
                    {microPhoneNotDetect ? (
                      <>
                        <p className="font-semibold mb-6 text-xl">
                          Can't find you microphone
                        </p>
                        <div className="mt-[2px]">
                          <p className="text-md mb-3 flex ">
                            <span className="min-w-[22px]">1. </span>
                            <span>
                              Verify your system configuration to ensure the
                              presence of a microphone.
                            </span>
                          </p>
                          <p className="text-md flex ">
                            <span className="min-w-[22px]">2. </span>
                            <span>
                              {" "}
                              If a microphone is not available, connect one.
                              Following that, restart your browser.
                            </span>{" "}
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <p className="font-semibold mb-6 text-xl">
                          Echoscript has been blocked from using your microphone{" "}
                        </p>
                        <div className="mt-[2px]">
                          <p className="text-md mb-3 flex ">
                            <span className="min-w-[22px]">1. </span>
                            <span>
                              Click the{" "}
                              <Info className="inline w-5 h-5 cursor-pointer" />{" "}
                              view site information icon in your browser's
                              address bar{" "}
                            </span>
                          </p>
                          <p className="text-md flex ">
                            <span className="min-w-[22px]">2. </span>
                            <span>
                              {" "}
                              Turn on microphone & refresh your page
                            </span>{" "}
                          </p>
                        </div>
                      </>
                    )}
                  </>
                </AlertModal>
              </div>
            </div>
            {!isRecording && !isLoading ? (
              <div className="mt-6">
                <DraggableFileInput
                  loading={isLoading}
                  handleFile={handleFile}
                  setFile={setFile}
                  file={file}
                  allowedTypes={allowed_mimetype}
                  isMultiple={false}
                />
              </div>
            ) : (
              <></>
            )}
          </div>

          {(isLoading && typeOfRec !== "voice" && typeOfRec !== "content") ||
          (!fileForSubmit &&
            isLoading &&
            (typeOfRec === "voice" || typeOfRec === "content")) ? (
            <PageLoader />
          ) : (
            <div className={`flex flex-wrap ${!isRecording ? "w-[70%]" : ""}`}>
              <div
                className={`flex lg:flex-wrap items-center px-[15px] h-full w-[30%] 3xl:w-[40%] xl:w-full ${
                  !open?.recordingButton ? "hidden" : ""
                }`}
              >
                <div className="w-full">
                  <div className="border-[2px] border-[#6558f5] h-full p-4 rounded w-full flex items-center justify-center gap-2">
                    <canvas
                      className="w-full max-w-[400px] hidden"
                      ref={visualizationCanvasRef}
                      width={isTestingMicrophone ? 400 : 0}
                    ></canvas>
                    {!isTestingMicrophone && (
                      <>
                        <div
                          id="mic"
                          className="w-[calc(100%_-_100px)] md:w-full"
                        ></div>
                        {isRecording && (
                          <Stopwatch
                            isRunningTime={isRecording}
                            setIsRecording={setIsRecording}
                            isPausedTime={isPaused}
                            stopRecording={stopRecording}
                          />
                        )}
                      </>
                    )}
                  </div>
                  <div>
                    {open.recordingButton && (
                      <RecordingButtonPage
                        className="mt-5 w-auto flex sm:flex-wrap sm:justify-start items-center justify-between gap-4"
                        stopRecording={() => {
                          publishRef?.current?.click();
                          setOpen({
                            recordingButton: false,
                            transcript: false,
                            defaultPage: false,
                            recordingStart: false,
                            microPhoneErrorAlert: false,
                          });
                          stopRecording();
                        }}
                        pauseResumeRecording={pauseResumeRecording}
                        restartRecording={restartRecording}
                        deleteRecording={() => {
                          setIsDeleteAlert(true);
                        }}
                        isRecording={isRecording}
                        isPaused={isPaused}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div
                className={`${isNoteOpen ? "px-[15px]" : "hidden"} ${
                  isRecording ? "w-[70%] 3xl:w-[60%] xl:w-full mt-4" : "w-full"
                }`}
              >
                {currentNote?.id && isNoteOpen && (
                  <>
                    {/* {headerOfTipTapNote()} */}
                    <Note
                      content={currentNote?.content}
                      isEdit={true}
                      isLoading={isLoading}
                      mainDivClassName=""
                      isLoadingSave={false}
                      // needAlert={true}
                      needMenu={true}
                      onSave={onSaveTipTapNote}
                      saveBtnName="Publish"
                      publishRef={publishRef}
                      contentId={currentNote?.id}
                      cancelBtnName="Close"
                      onCancel={() => {
                        setIsNoteOpen(false);
                        setCurrentNote(undefined);
                      }}
                      fileName={headerOfTipTapNote}
                      description={description ? description : ""}
                      setDescription={setDescription}
                      setIsSaveDefault={
                        currentNote?.isTemplate ? null : setIsSaveDefault
                      }
                    />
                    {!currentNote?.isTemplate && (
                      <AlertModal
                        visible={isSaveDefault}
                        showFooter
                        showCrossIcon
                        onSubmit={() => onDefaultSave(currentNote?.isTemplate)}
                        onClose={() => setIsSaveDefault(false)}
                        onCancel={() => setIsSaveDefault(false)}
                        submitButtonText="Save"
                      >
                        <div className="text-center">
                          <p>Select Note type</p>
                          <MultiSelect
                            options={saveOption}
                            setShowError={setShowError}
                            selectedValue={playbookType}
                            setSelectedValue={setPlaybookType}
                          />
                          <p className="text-red-700 text-sm">
                            {showError && "Please select option"}
                          </p>
                        </div>
                      </AlertModal>
                    )}
                  </>
                )}
              </div>
              <AlertModal
                visible={isDeleteAlert}
                onClose={() => {
                  setIsDeleteAlert(false);
                }}
                onCancel={() => {
                  setIsDeleteAlert(false);
                }}
                onSubmit={() => {
                  deleteRecording();
                  setIsDeleteAlert(false);
                }}
                submitButtonText="Yes"
              >
                <p className="text-[18px] font-Biotif__SemiBold text-textDark text-center w-[300px] max-w-full mx-auto">
                  Are you sure you want to delete Recording ?
                </p>
              </AlertModal>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AudioRecorderPage;
