// ** packages **
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
// ** components **
import AudioRecorderPage from "./components/AudioRecorder";
import Image from "components/Image";
import Breadcrumbs from "components/Breadcrumbs";
// ** services **
import { useGetPatientAPI } from "modules/Patient/services";
// ** types **
import { PatientDetailType } from "modules/Patient/types/patients.types";
// ** constants **
import { PRIVATE_NAVIGATION } from "constants/navigation.constant";
import MainLoader from "components/Loaders/MainLoader";

const PatientRecording = () => {
  const { id } = useParams();

  const initialData = {
    name: "",
    contactNumber: null,
    file: null,
    email: "",
    id: undefined,
    profile: "",
  };
  const [patientInfoData, setPatientInfoData] =
    useState<PatientDetailType>(initialData);

  const { getPatientApi, isLoading } = useGetPatientAPI();

  const patientInfo = async () => {
    if (id) {
      const { data, error } = await getPatientApi(Number(id));
      if (data && !error) {
        setPatientInfoData({
          name: data?.name,
          contactNumber: data?.contact_number,
          file: data?.file,
          email: data?.email,
          id: data?.id,
          profile: data?.profile,
        });
      }
    }
  };

  useEffect(() => {
    patientInfo();
  }, [id]);

  const currentBreadCrumb = {
    trails: [
      {
        title: "Dashboard",
        path: PRIVATE_NAVIGATION.dashboard.view,
      },
      {
        title: "Patient",
        path: PRIVATE_NAVIGATION.patient.view,
      },
      {
        title: patientInfoData?.name ?? "Patient Name",
        path: PRIVATE_NAVIGATION.patient.detail.view(
          String(patientInfoData?.id)
        ),
      },
    ],
    title: "Recording",
  };

  return (
    <div className="bg-white h-full">
      {isLoading && <MainLoader />}
      {/* <div>
        <Breadcrumbs path={currentBreadCrumb} />
      </div> */}
      <div className="flex xl:flex-col xl:items-start justify-between rounded-md px-3 py-4 items-center">
        <div className="flex justify-between items-center pt-[5px]">
          <div className="w-full flex items-center">
            <div className="inline-block shrink-0">
              <Image
                avatarWrapperClassName="w-[50px] h-[50px]"
                avatarInnerWrapperClassName="!rounded-full"
                imgPath={patientInfoData.profile}
                first_name={patientInfoData.name}
                last_name={patientInfoData.name}
              />
            </div>
            <h3 className="text-[16px] font-semibold text-textDark w-full whitespace-pre overflow-hidden text-ellipsis pl-[12px]">
              {patientInfoData?.name || ""}
            </h3>
          </div>
        </div>
      </div>
      {patientInfoData?.id && (
        <AudioRecorderPage refId={patientInfoData?.id} typeOfRec="patient" />
      )}
    </div>
  );
};
export default PatientRecording;
