// ** packages **
import { useEffect, useMemo, useState } from "react";
import { Eye, RotateCw } from "react-feather";
import { useNavigate } from "react-router-dom";
// ** components **
import TableCellRender from "components/DataTable/components/TableCellRender";
import SvgIcon from "components/Icon/SvgIcon";

// ** constants **
import { PRIVATE_NAVIGATION } from "constants/navigation.constant";
// ** helper **
import DateFormat from "components/DateFormat";
import { HoursMinSecFormat, firstCharUpperCase } from "utils";
import { useRecordNotesColumnPropstype } from "../types/patients.types";
import TranscriptionStatus from "components/Badge/TranscriptionStatus";
import TranscriptionBtn from "components/GroupBtn/TranscriptionBtn";
import usePermission from "hooks/usePermission";
import { PERMISSIONS } from "constants/Permissions.constant";
import { useDeleteTranscriptAPI } from "../sub-modules/Transcription/services";
import Button from "components/Theme/Button";
import CheckBoxField from "components/FormField/common/CheckBoxField";

const useRecordNotesColumn = (props: useRecordNotesColumnPropstype) => {
  const {
    onRetryTranscript,
    isRetryLoading,
    currentRetryId,
    onDeleteClick,
    selectedIdsArr,
    setSelectedIdsArr,
    patientName,
  } = props;  
  const navigate = useNavigate();
  const { hasPermission } = usePermission();
  const hasNoteDeletePermission = hasPermission(PERMISSIONS.NOTES.DELETE);
  const navigateToTranscript = (
    id: string | number,
    OpenSummary: boolean = false,
    mode: string = "patient"
  ) => {
    navigate(PRIVATE_NAVIGATION.transcription.detail.view(String(id)), {
      state: {
        mode: "patient",
        tab: {
          summary: OpenSummary,
          transcript: !OpenSummary,
          share: false,
          playbook: false,
        },
        name: patientName,
      },
    });
  };

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const handleResize = () => {
    if (
      (window.innerWidth > 1300 && windowSize < 1300) ||
      (window.innerWidth < 1300 && windowSize >= 1300)
    ) {
      setWindowSize(window.innerWidth);
    }
  };

  const handleCheckboxChange = (id: number, isChecked: boolean) => {
    setSelectedIdsArr((prevSelectedIds: any = []) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter(
          (selectedId: number) => selectedId !== id
        );
      } else {
        if (isChecked && id) {
          return [...prevSelectedIds, id];
        }
        return prevSelectedIds;
      }
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowSize]);

  const columnDefs = useMemo(
    () => [
      {
        // name: "Select",
        selector: (row: { id: string | number }) => row.id,
        sortable: false,
        width: "1%",
        cell: (row: {
          id: string | number;
          status: string;
          note_type: string;
        }) => (
          <TableCellRender
            cellRowData={
              <div className="custom__checkbox__SD without__label">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    handleCheckboxChange(+row.id, e.target.checked);
                  }}
                  checked={selectedIdsArr?.includes(+row.id)}
                />
                <label className="label__SD"></label>
              </div>
            }
          />
        ),
      },
      {
        name: "Name",
        selector: (row: { name: string }) => row?.name,
        sortable: true,
        sortField: "name",
        width: "70%",
        cell: (row: {
          name: string;

          uploaded: string;
          created: { first_name: string; last_name: string };
          duration: string;
          is_active: boolean;
          status: string;
          note_type: string;
          id: number | string;
        }) => (
          <TableCellRender
            // className="overflow-hidden"
            cellRowData={
              <div
                className="py-2 cursor-pointer flex items-center justify-between"
                onClick={() => navigateToTranscript(row?.id, true)}
              >
                <div>
                  <div className="flex gap-2 items-center">
                    <span className="font-medium text-gray-900 truncate max-w-32">
                      {row.name}
                    </span>
                    <span className="flex gap-2 text-gray-500"> &#x2022; </span>
                    {row?.status && (
                      <div className="checkmark">
                        <TranscriptionStatus status={row?.status} />
                      </div>
                    )}
                  </div>
                  <div className="lg:ml-0 lg:mt-1 break-all">
                    <div className="flex gap-2 items-center text-gray-500">
                      <span className="text-[13px] ">
                        {row?.created &&
                          `${firstCharUpperCase(
                            row?.created?.first_name
                          )} ${firstCharUpperCase(
                            row?.created?.last_name
                          )}`}{" "}
                        &#x2022;{" "}
                        {row.uploaded && (
                          <DateFormat
                            date={row.uploaded}
                            format="dd MMM yyyy"
                          />
                        )}
                        {/* &#x2022;{" "}
                        {row?.created &&
                          `${HoursMinSecFormat(parseInt(row.duration))}`} */}
                      </span>
                    </div>
                  </div>
                </div>
                <TranscriptionBtn
                  onDelete={onDeleteClick}
                  hasNoteDeletePermission={hasNoteDeletePermission}
                  id={row?.id}
                  status={row?.status}
                  onClick={navigateToTranscript}
                  onClickFailed={onRetryTranscript}
                  isRetryLoading={isRetryLoading}
                  currentRetryId={row?.id}
                  noteType={row?.note_type}
                />
              </div>
            }
          />
        ),
      },
      // {
      //   name: "Action",
      //   selector: (row: { id: string | number }) => row.id,
      //   sortable: false,
      //   width: "30%",
      //   cell: (row: {
      //     id: string | number;
      //     status: string;
      //     note_type: string;
      //   }) => (
      //     <TableCellRender
      //       cellRowData={
      //         <TranscriptionBtn
      //           onDelete={onDeleteClick}
      //           hasNoteDeletePermission={hasNoteDeletePermission}
      //           id={row?.id}
      //           status={row?.status}
      //           onClick={navigateToTranscript}
      //           onClickFailed={onRetryTranscript}
      //           isRetryLoading={isRetryLoading}
      //           currentRetryId={row?.id}
      //           noteType={row?.note_type}
      //         />
      //       }
      //     />
      //   ),
      // },
    ],
    // () => [
    //   windowSize > 1300 && {
    //     name: "Select",
    //     selector: (row: { id: string | number }) => row.id,
    //     width: "10px",
    //     sortable: false,
    //     cell: (row: {
    //       id: string | number;
    //       status: string;
    //       note_type: string;
    //     }) => (
    //       <TableCellRender
    //         cellRowData={
    //           <CheckBoxField
    //             onChange={(e) => {
    //               handleCheckboxChange(+row.id, e.target.checked);
    //             }}
    //           />
    //         }
    //       />
    //     ),
    //   },
    //   (windowSize > 1300) ? {
    //     name: "Name",
    //     selector: (row: { name: string }) => row.name,
    //     sortable: true,
    //     sortField: "name",
    //     cell: (row: { name: string; id: number | string; status: string | null | undefined }) => {
    //       return (
    //         <TableCellRender
    //         className="w-100 container"
    //           cellRowData={
    //             <div className="flex items-center justify-between">
    //               <span
    //                 className="cursor-pointer w-full" onClick={() =>
    //                   navigateToTranscript(row?.id, false)}
    //               >
    //                 {row.name}
    //               </span>
    //               {row?.status && (row?.status?.toLowerCase() === "failed" ||
    //               row?.status?.toLowerCase() === "rejected") ? <></> :
    //               <span className="isolate inline-flex rounded-md shadow-sm">
    //                 <Button
    //                   type="button"
    //                   className="relative self-end -ml-px inline-flex items-center rounded-r-md bg-white px-2 py-3 text-sm font-semibold text-gray-900 hover:bg-indigo-100 hover:ring-primaryColor focus:z-10"
    //                   onClick={() => navigateToTranscript(row?.id, true)}>
    //                   <SvgIcon iconName="AINotesIcon" className="" />
    //                 </Button>
    //               </span>
    //             }
    //             </div>
    //           }
    //         />
    //       )
    //     }
    //   }: {
    //     name: "Name",
    //     selector: (row: { name: string, status: string }) => row.name,
    //     sortable: true,
    //     sortField: "name",
    //     cell: (row: {
    //       name: string;
    //       status: string;
    //       created: { first_name: string; last_name: string }
    //       uploaded: string
    //       id: number;
    //     }) => (
    //       <TableCellRender
    //         className="flex overflow-hidden w-[90vw]"
    //         cellRowData={
    //           <>
    //             <div
    //               className="container mx-auto px-4 py-8 cursor-pointer"
    //               onClick={() => navigateToTranscript(row?.id, true)}
    //             >
    //               <div className="flex gap-2 items-center">
    //                 <span className="font-medium text-gray-900 truncate">{row.name}</span>
    //                 <div className="checkmark">
    //                   <TranscriptionStatus status={row?.status} />
    //                 </div>
    //               </div>
    //               <div className="lg:ml-0 lg:mt-1">
    //                 <div className="flex gap-2 truncate mt-1 text-gray-500">
    //                   {row?.created && <span>Uploaded by {row?.created?.first_name + " " + row?.created?.last_name}</span>}
    //                   &#x2022;{" "}{row.uploaded && <DateFormat date={row.uploaded} format="dd MMM yyyy" />}
    //                 </div>
    //               </div>
    //             </div>
    //           </>
    //         }
    //       />
    //     ),
    //   },
    //   (windowSize > 1300) && {
    //     name: "Uploaded By",
    //     selector: (row: {
    //       created: { first_name: string; last_name: string };
    //     }) => row?.created?.first_name,
    //     sortField: "first_name",
    //     width: "10%",
    //     sortable: false,
    //     cell: (row: { created: { first_name: string; last_name: string } }) => (
    //       <TableCellRender
    //         cellRowData={
    //           firstCharUpperCase(row?.created?.first_name) +
    //           " " +
    //           firstCharUpperCase(row?.created?.last_name)
    //         }
    //       />
    //     ),
    //   },
    //   (windowSize > 1300) && {
    //     name: "Uploaded Date",
    //     selector: (row: { uploaded: string }) => row.uploaded,
    //     sortField: "uploaded",
    //     sortable: true,
    //     cell: (row: { uploaded: string }) => (
    //       <TableCellRender
    //         cellRowData={
    //           <DateFormat date={row.uploaded} format="dd MMM yyyy, hh:mm a" />
    //         }
    //       />
    //     ),
    //   },
    //   (windowSize > 1300) && {
    //     name: "Duration",
    //     width: "10%",
    //     selector: (row: { duration: { duration: number } }) => row.duration,
    //     sortable: false,
    //     cell: (row: { duration: number }) => (
    //       <TableCellRender cellRowData={HoursMinSecFormat(row.duration)} />
    //     ),
    //   },
    //   (windowSize > 1300) && {
    //     name: "Status",
    //     width: "10%",
    //     selector: (row: { status: string | number }) => row.status,
    //     sortField: "status",
    //     sortable: true,
    //     cell: (row: { status: string }) => (
    //       <TableCellRender
    //         cellRowData={
    //           <div className="checkmark">
    //             <TranscriptionStatus status={row?.status} />
    //           </div>
    //         }
    //       />
    //     ),
    //   },
    //   {
    //     name: "Action",
    //     selector: (row: { appointment_datetime: string }) =>
    //       row.appointment_datetime,
    //     sortable: false,
    //     cell: (row: { id: string; patient_id: string; status: string }) => {
    //       return (
    //         <>
    //           <TranscriptionBtn
    //             onDelete={onDeleteClick}
    //             hasNoteDeletePermission={hasNoteDeletePermission}
    //             id={row?.id}
    //             status={row?.status}
    //             onClick={navigateToTranscript}
    //             onClickFailed={onRetryTranscript}
    //             isRetryLoading={isRetryLoading}
    //             currentRetryId={currentRetryId}
    //           />
    //         </>
    //       );
    //     },
    //   },
    // ],
    [selectedIdsArr, patientName]
  );
  return columnDefs;
};

export default useRecordNotesColumn;
