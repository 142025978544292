// ** packages **
import * as yup from "yup";
// ** error message  **
import { addPatientModelSchemaError } from "constants/formErrorMessage.constant";
import { TableRefType } from "components/DataTable";
import { paramIdsType } from "components/Layout/components/Sidebar2";

export const addMeetingSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required(addPatientModelSchemaError.name.name)
    .min(3, addPatientModelSchemaError.name.minLengthName)
    .max(75, addPatientModelSchemaError.name.maxLengthName),
});

export type meetingAllNotesTypeProps = {
  meetingId: string | number | undefined;
  tableRef: React.RefObject<TableRefType>;
  setSearchValue?: any;
  searchData?: string;
  setIsParamID?: React.Dispatch<React.SetStateAction<paramIdsType>>;
  // setFolderName?: React.Dispatch<React.SetStateAction<string | null>>;
};

export type meetingSideBarPropsType = {
  meetingId: number | string;
  setIsSideOpen: React.Dispatch<React.SetStateAction<boolean>>;
  tableRef: React.RefObject<TableRefType>;
  isSideOpen: boolean;
};
