import Breadcrumbs from "components/Breadcrumbs";
import AlertModal from "components/modal/AlertModal";
import { BREAD_CRUMB } from "constants/breadcrumbs.constant";
import PlaybookTemplateList from "modules/Admin/sub-module/AdminPrompt/components/PlaybookTemplateList";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAuth } from "redux/slices/authSlice";

const PlaybookSettingUser = () => {
  const { user } = useSelector(getAuth);
  const [isPlaybookCreatePermission, setIsPlaybookCreatePermission] =
    useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    user?.current_plan?.payment_data?.plan?.specification.forEach(
      (spec: any) => {
        if (spec.name_type === "playbook" && spec.limit === "false") {
          setIsPlaybookCreatePermission(true);
        }
      }
    );
  }, []);

  return (
    <div>
      <Breadcrumbs path={BREAD_CRUMB.PlaybooksSetting} />
      <AlertModal
        visible={isPlaybookCreatePermission}
        onClose={() => navigate("/")}
        showCrossIcon={true}
        showCancelButton={false}
        onSubmit={() => navigate("/subscription")}
        submitButtonText="Subscription"
      >
        <div>You do not have any permission to access Playbook. Upgrade to access & enjoy its features.</div>
      </AlertModal>
      {
        // <div className="bg-bgWhiteSD view-prompt-modal rounded-[10px] shadow-raiseShadow p-[24px] sm:p-[12px] pb-[16px] mt-[20px]">
          <PlaybookTemplateList />
        // </div>
      }
    </div>
  );
};
export default PlaybookSettingUser;
