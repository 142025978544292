import Spinner from "components/Loaders/Spinner";
import { useCallback, useEffect, useRef, useState } from "react";
import { firstCharUpperCase } from "utils";
function CheckIcon({ className }: { className: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className={className}
    >
      <path
        fillRule="evenodd"
        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export function ChevronDownIcon({ className }: { className: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className={className}
    >
      <path
        fillRule="evenodd"
        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export type optionsType = {
  id: number | null;
  title: string | null;
  description?: string | null;
  status?: boolean;
  isDefault?: boolean;
};

type selectMenuPropsType = {
  publishingOptions: optionsType[];
  selected?: optionsType;
  onSelect: (value: optionsType) => void;
  ulClassName?: string;
  isLoading?: boolean;
  smallSpinnerClassName?: string;
  title?: string;
  wantCheckInTitle?: boolean;
  wantTickFromStatus?: boolean;
  needHover?: boolean;
  onTextClick?: () => void;
  titleIcon?: React.ReactNode;
  titleDivClass?: string;
  noOptionMessage?: string;
  noOptionMessageDescription?: string;
  mainTitleDivClass?: string;
  buttonClick?: boolean | null;
  onButtonClick?: () => void;
};

export default function SelectMenu(props: selectMenuPropsType) {
  const {
    publishingOptions,
    selected,
    onSelect,
    isLoading = false,
    ulClassName = "max-h-[248px] overflow-y-auto right-0",
    smallSpinnerClassName = "",
    title,
    titleIcon,
    wantCheckInTitle = true,
    wantTickFromStatus = false,
    needHover = false,
    titleDivClass = "",
    onTextClick = () => {},
    noOptionMessage = "No options found",
    noOptionMessageDescription = undefined,
    mainTitleDivClass = "",
    buttonClick = false,
    onButtonClick = () => {},
  } = props;
  const [open, setOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onMouseLeaveDiv = useCallback(() => {
    timeoutRef.current = setTimeout(() => {
      if (open && needHover) {
        setOpen(false);
      }
    }, 300);
  }, [open, needHover]);

  const onMouseEnterDiv = () => {
    if (!open && needHover) {
      setOpen(true);
    }
  };

  const onMouseLeaveUl = () => {
    if (open && needHover) {
      setOpen(false);
    }
  };

  const onMouseEnterUL = () => {
    if (timeoutRef?.current) {
      clearTimeout(timeoutRef?.current);
    }
  };

  return (
    <div
      className="relative rounded-lg"
      ref={dropdownRef}
      onMouseEnter={onMouseEnterDiv}
      onMouseLeave={onMouseLeaveDiv}
    >
      <div className={`flex rounded-lg gap-0 w-full ${mainTitleDivClass}`}>
        <div
          className={`cursor-pointer btn-text flex items-center rounded-l-lg overflow-hidden gap-x-1.5 border-indigo-700 border-r-2 px-4 py-3 text-white bg-indigo-600 text-sm font-normal w-full max-w-full ${titleDivClass} ${
            buttonClick && "cursor-pointer"
          }`}
          onClick={() => {
            onTextClick();
            onButtonClick();
            setOpen(!open);
          }}
        >
          {isLoading ? (
            <Spinner
              smallSpinnerClassName={`mr-[10px] ${smallSpinnerClassName}`}
            />
          ) : (
            <>
              {wantCheckInTitle && selected?.id !== 0 && (
                <CheckIcon className="-ml-0.5 h-5 w-5" />
              )}
            </>
          )}

          {titleIcon && titleIcon}
          {selected?.title === "Select AI options" ? (
            <svg
              className="w-5 h-5 "
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 4000 4000"
            >
              <g id="Layer_2" data-name="Layer 2">
                <g>
                  <path
                    d="m2388.19,1034.58l112.81,434.11c96.35,370.79,385.91,660.34,756.69,756.69l434.11,112.81-434.11,112.81c-370.79,96.35-660.34,385.91-756.69,756.69l-112.81,434.11-112.81-434.11c-96.35-370.79-385.91-660.34-756.69-756.69l-434.11-112.81,434.11-112.81c370.79-96.35,660.34-385.91,756.69-756.69l112.81-434.11Z"
                    fill="#fff"
                    strokeWidth="0"
                  ></path>
                  <path
                    d="m871.53,971.53l48.75,187.59c41.64,160.23,166.76,285.35,326.99,326.99l187.59,48.75-187.59,48.75c-160.23,41.64-285.35,166.76-326.99,326.99l-48.75,187.59-48.75-187.59c-41.64-160.23-166.76-285.35-326.99-326.99l-187.59-48.75,187.59-48.75c160.23-41.64,285.35-166.76,326.99-326.99l48.75-187.59Z"
                    fill="#fff"
                    strokeWidth="0"
                  ></path>
                  <path
                    d="m1698.19,358.19l36.63,140.97c31.29,120.41,125.32,214.44,245.73,245.73l140.97,36.63-140.97,36.63c-120.41,31.29-214.44,125.32-245.73,245.73l-36.63,140.97-36.63-140.97c-31.29-120.41-125.32-214.44-245.73-245.73l-140.97-36.63,140.97-36.63c120.41-31.29,214.44-125.32,245.73-245.73l36.63-140.97Z"
                    fill="#fff"
                    strokeWidth="0"
                  ></path>
                </g>
              </g>
            </svg>
          ) : (
            <p className="font-semibold text-ellipsis whitespace-nowrap overflow-hidden max-w-[100px]">
              {firstCharUpperCase(title ? title || "" : selected?.title || "")}
            </p>
          )}
        </div>

        <div
          className="inline-flex items-center px-2 py-3 text-white cursor-pointer hover:bg-indigo-800 bg-indigo-600 rounded-r-lg"
          onClick={() => setOpen(!open)}
        >
          <span className="sr-only">Change published status</span>
          <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
        </div>
      </div>

      <ul
        className={`${ulClassName} absolute z-10 w-60 md:min-w-40 md:w-full mt-2 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${
          open ? "block" : "hidden"
        }`}
        onMouseLeave={onMouseLeaveUl}
        onMouseEnter={onMouseEnterUL}
      >
        {publishingOptions?.length === 0 ? (
          <li
            className={`text-gray-900 cursor-pointer select-none p-4 text-sm hover:bg-indigo-200 hover:text-gray-900`}
            onClick={() => {
              setOpen(false);
            }}
          >
            <div className="flex flex-col">
              <div className="flex justify-between">
                <p className={"font-semibold"}>{noOptionMessage}</p>
              </div>
              <p className="mt-2 text-gray-500 text-sm">
                {noOptionMessageDescription}
              </p>
            </div>
          </li>
        ) : (
          publishingOptions?.map((option, index: number) => (
            <li
              key={option?.title + " " + index}
              className={`text-gray-900 cursor-pointer select-none p-4 text-sm hover:bg-indigo-200 hover:text-gray-900`}
              onClick={() => {
                onSelect(option);
                setOpen(false);
              }}
            >
              <div className="flex flex-col">
                <div className="flex justify-between">
                  <p className={"font-semibold"}>{option?.title}</p>
                  {wantTickFromStatus
                    ? option?.status && (
                        <span className="text-indigo-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )
                    : selected?.title?.toLowerCase() ===
                        option?.title?.toLowerCase() && (
                        <span className="text-indigo-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                </div>
                {option?.description && (
                  <p className="mt-2 text-gray-500 text-sm">
                    {option?.description}
                  </p>
                )}
              </div>
            </li>
          ))
        )}
      </ul>
    </div>
  );
}
