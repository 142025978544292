import SvgIcon from "components/Icon/SvgIcon";
import Button from "components/Theme/Button";
import AlertModal from "components/modal/AlertModal";
import { useEffect, useState } from "react";

type TranscriptionBtnPropsType = {
  onClick: (id: number | string, OpenSummary: boolean, mode?: string) => void;
  className?: string;
  id: number | string;
  status: string;
  onClickFailed: (id: number) => void;
  isRetryLoading: boolean;
  currentRetryId: null | string | number;
  noteType?: string;
  onDelete: (id: number | string) => void;
  hasNoteDeletePermission: boolean;
};

const TranscriptionBtn = (props: TranscriptionBtnPropsType) => {
  const {
    onClick,
    id,
    status,
    onClickFailed,
    isRetryLoading,
    currentRetryId,
    noteType,
    onDelete,
    hasNoteDeletePermission,
  } = props;

  return (
    <>
      <span className="isolate inline-flex rounded-md shadow-none">
        {/* {hasNoteDeletePermission && (
          <Button
            type="button"
            className="relative inline-flex items-center rounded-l-md ml-2 mr-2 bg-white px-2 py-3 text-sm font-semibold text-black hover:bg-indigo-100 hover:ring-primaryColor focus:z-10"
            onClick={() => onDelete(id)}
          >
            <SvgIcon iconName="DeleteIconDark" className="" />
          </Button>
        )} */}
        {(status.toLowerCase() === "failed" ||
          status.toLowerCase() === "rejected" ||
          status.toLowerCase() === "not generated") && (
          <>
            <Button
              type="button"
              className="relative inline-flex items-center rounded-md bg-white px-[4px] py-1 text-sm font-semibold text-gray-900 hover:bg-indigo-100 hover:ring-primaryColor focus:z-10"
              onClick={() => onClickFailed(parseInt(id as string))}
              loading={currentRetryId === id && isRetryLoading}
            >
              {status.toLowerCase() === "not generated" ? (
                // Slack Icons for the same
                <SvgIcon iconName="slack" className="w-4 h-4" />
              ) : (
                // Retry
                <svg
                  className="w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="512"
                  height="512"
                  x="0"
                  y="0"
                  viewBox="0 0 512 512"
                >
                  <g>
                    <path
                      d="M437.02 75.112C388.667 26.76 324.38.133 256 .133c-41.218 0-82.189 10.033-118.485 29.016C116.586 40.094 97.191 54.029 80 70.302V.133H40v140h140v-40h-73.338c39.887-38.075 93.411-60 149.338-60 119.102 0 216 96.896 216 215.999a220.284 220.284 0 0 1-.901 19.87l39.836 3.628A260.53 260.53 0 0 0 512 256.135l-20-.002h20c0-68.381-26.629-132.667-74.98-181.021zM512 256.132v.001zM87.724 391.566l-31.145 25.101a258.108 258.108 0 0 0 29.92 31.315l26.494-29.967a217.903 217.903 0 0 1-25.269-26.449zM52.219 327.943l-37.727 13.296a255.633 255.633 0 0 0 17.842 39.517l34.926-19.497a215.468 215.468 0 0 1-15.041-33.316zM40 256.133H0c0 14.47 1.22 28.986 3.624 43.145l39.436-6.698A218.198 218.198 0 0 1 40 256.133zM142.33 439.841l-21.082 33.993a255.911 255.911 0 0 0 38.618 19.629l15.029-37.069a215.7 215.7 0 0 1-32.565-16.553zM464.717 311.981a215.221 215.221 0 0 1-12.395 34.365l36.335 16.727a255.154 255.154 0 0 0 14.706-40.777l-38.646-10.315zM383.554 430.468a216.608 216.608 0 0 1-31.213 19.047l17.867 35.788a256.703 256.703 0 0 0 36.994-22.574l-23.648-32.261zM434.282 378.123a217.965 217.965 0 0 1-23.152 28.311l28.725 27.837a257.892 257.892 0 0 0 27.415-33.527l-32.988-22.621zM318.37 462.995a215.371 215.371 0 0 1-35.756 7.515l4.873 39.702a255.636 255.636 0 0 0 42.412-8.914l-11.529-38.303zM209.794 467.185l-8.514 39.083a256.918 256.918 0 0 0 42.953 5.6l1.809-39.959a216.978 216.978 0 0 1-36.248-4.724z"
                      fill="#231f20"
                      opacity="1"
                      data-original="#000000"
                    ></path>
                  </g>
                </svg>
              )}
            </Button>
          </>
        )}
      </span>
    </>
  );
};
export default TranscriptionBtn;
