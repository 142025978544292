import { AxiosRequestConfig } from "axios";
import { useAxiosGet, useAxiosPost, useAxiosPut } from "hooks/useaxios";

const All_PLANS_BASE_PATH = "plans";
export const useGetSuperAdminPlans = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getSuperAdminPlansApi = async () => {
    return callApi(`${All_PLANS_BASE_PATH}/get_superadmin_plans`);
  };

  return { getSuperAdminPlansApi, isLoading, isError, isSuccess };
};

export const useGetAIModules = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getAIModulesApi = async () => {
    return callApi(`${All_PLANS_BASE_PATH}/aimodule_list`);
  };

  return { getAIModulesApi, isLoading, isError, isSuccess };
};

export const useAddSuperAdminPlanPost = () => {
  // ** custom hooks for post **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const addSuperAdminPlanApi = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${All_PLANS_BASE_PATH}/add_plan`, data, config);
  };
  return { addSuperAdminPlanApi, isLoading, isError, isSuccess };
};

export const usePutSuperAdminSubscriptionPlans = () => {
  // ** custom hook for update plan
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
  const updateSubscriptionPaymentPlanApi = async (
    id: number | string,
    payload: any
  ) => {
    const response = await callApi(
      `${All_PLANS_BASE_PATH}/update_plans_price/${id}`,
      { ...payload }
    );
    return response;
  };

  return { updateSubscriptionPaymentPlanApi, isLoading, isError, isSuccess };
};
