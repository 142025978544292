import { IconInputProps } from ".";

export const DashboardIcon = ({ className }: IconInputProps) => {
  return (
    <svg className={className ?? ""} width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="2.71186" height="20" fill="#4B5C6B"/>
      <rect x="4.71186" y="6.08696" width="2.71186" height="13.913" fill="#4B5C6B"/>
      <rect x="9.42373" y="2.6087" width="2.71186" height="17.3913" fill="#4B5C6B"/>
      <rect x="14.1356" y="11.3044" width="2.71186" height="8.69565" fill="#4B5C6B"/>
    </svg>
  );
};
