import { constant } from "lodash";
import AddUpdatePlan from "./AddUpdatePlan";
import { UpdatePlanType } from "../types";
import { PERMISSIONS } from "constants/Permissions.constant";

const contants = {
  id: 0,
  plan_name: "",
  description: "",
  price_id: { label: "", value: "" },
  price: "",
  prices: [
    {
      currency: "USD",
      price_amount: 49,
      price_id: "price_1PutFgRoaqG1AXh4vuTNtGbY",
    },
  ],
  specification: [
    {
      id: 1,
      limit: "0",
      name: "Add Patients limit per day",
      name_type: "patient",
      permission: PERMISSIONS.PATIENT.CREATE,
    },
    {
      id: 2,
      limit: "0",
      name: "Generate Notes limit per day",
      name_type: "notes",
      permission:PERMISSIONS.NOTES.READ
    },
    {
      id: 3,
      limit: "0",
      name: "Generate transcript limit per day",
      name_type: "transcript",
      permission:PERMISSIONS.NOTES.CREATE
    },
    {
      id: 4,
      limit: "0",
      name: "Record minutes limit per recording",
      name_type: "recording",
      permission:PERMISSIONS.USER.READ, // it given for temporirly to handle flow
    },
    {
      id: 5,
      limit: "false",
      name: "Create playbooks",
      name_type: "playbook",
      permission:PERMISSIONS.PLAYBOOK.CREATE
    },
    {
      id: 6,
      limit: "false",
      name: "Generate custom prompts",
      name_type: "custom prompt",
      permission:PERMISSIONS.CUSTOM_PROMPT.CREATE
    },
  ],
};

const AddNewSuperPlan = (props: any) => {
  const { planData, setUpdateAddBack, setSuperPlanData } = props;

  return (
    <div>
      <AddUpdatePlan
        addPlan={true}
        planData={planData}
        setUpdateAddBack={setUpdateAddBack}
        selectedPlanData={contants}
        setSuperPlanData={setSuperPlanData}
      />
    </div>
  );
};

export default AddNewSuperPlan;
