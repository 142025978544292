// ** packages **
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// ** components **
import TableCellRender from "components/DataTable/components/TableCellRender";

// ** constants **
import { PRIVATE_NAVIGATION } from "constants/navigation.constant";

// ** helper **
import DateFormat from "components/DateFormat";
import { HoursMinSecFormat, firstCharUpperCase } from "utils";
import TranscriptionStatus from "components/Badge/TranscriptionStatus";
import TranscriptionBtn from "components/GroupBtn/TranscriptionBtn";

//** types **
import { useMeetingColumnPropsType } from "../types/meeting.types";
import usePermission from "hooks/usePermission";
import { PERMISSIONS } from "constants/Permissions.constant";
import { useDeleteTranscriptAPI } from "modules/Patient/sub-modules/Transcription/services";
import Button from "components/Theme/Button";
import SvgIcon from "components/Icon/SvgIcon";
import CheckBoxField from "components/FormField/common/CheckBoxField";

const useMeetingNotesColumnSidebar = (props: useMeetingColumnPropsType) => {
  const {
    onRetryTranscript,
    isRetryLoading,
    currentRetryId,
    onDeleteClick,
    selectedIdsArr,
    folderName,
    setSelectedIdsArr,
  } = props;
  
  const navigate = useNavigate();
  const { hasPermission } = usePermission();
  const [id, setId] = useState<number>(0);
  const params = useParams();
  const hasNoteDeletePermission = hasPermission(PERMISSIONS.NOTES.DELETE);
  const navigateToTranscript = (
    id: string | number,
    OpenSummary: boolean = false
  ) => {
    navigate(PRIVATE_NAVIGATION.transcription.detail.view(String(id)), {
      state: {
        mode: "meeting",
        tab: {
          summary: OpenSummary,
          transcript: !OpenSummary,
          share: false,
          playbook: false,
        },
        navHighlighter: "meeting",
        name: folderName
      },
    });
  };

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const handleResize = () => {
    if (
      (window.innerWidth > 1100 && windowSize < 1100) ||
      (window.innerWidth < 1100 && windowSize >= 1100)
    ) {
      setWindowSize(window.innerWidth);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowSize]);

  const handleCheckboxChange = (id: number, isChecked: boolean) => {
    setSelectedIdsArr((prevSelectedIds: any = []) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter(
          (selectedId: number) => selectedId !== id
        );
      } else {
        if (isChecked && id) {
          return [...prevSelectedIds, id];
        }
        return prevSelectedIds;
      }
    });
  };

  const columnDefs = useMemo(
    () => [
      {
        // name: "Select",
        selector: (row: { id: string | number }) => row.id,
        sortable: false,
        width: "50px",
        cell: (row: {
          id: string | number;
          status: string;
          note_type: string;
        }) => (
          <TableCellRender
            cellRowData={
              <div className="custom__checkbox__SD without__label">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    handleCheckboxChange(+row.id, e.target.checked);
                  }}
                  checked={selectedIdsArr?.includes(+row.id)}
                />
                <label className="label__SD"></label>
              </div>
            }
          />
        ),
      },
      {
        name: "Name",
        selector: (row: { name: string }) => row?.name,
        sortable: true,
        sortField: "name",
        width: "calc(100% - 100px)",
        cell: (row: {
          name: string;
          uploaded: string;
          created: { first_name: string; last_name: string };
          duration: string;
          is_active: boolean;
          status: string;
          note_type: string;
          id: number | string;
        }) => (
          <TableCellRender
            // className="overflow-hidden"
            cellRowData={
              <>
                <div
                  className="py-2 cursor-pointer flex items-center justify-between"
                  onClick={() => {
                    navigateToTranscript(row?.id, true)
                  }}
                >
                  <div>
                    <div className="flex gap-2 items-center">
                      <span className="font-medium text-gray-900 truncate max-w-32">
                        {row.name}
                      </span>
                      <span className="flex gap-2 text-gray-500">
                        {" "}
                        &#x2022;{" "}
                      </span>
                      {row?.status && (
                        <div className="checkmark">
                          <TranscriptionStatus status={row?.status} />
                        </div>
                      )}
                    </div>
                    <div className="">
                      <div className="flex gap-2 items-center text-gray-500 justify-start">
                        <span className="text-left text-[13px]">
                          {row?.created &&
                            `${firstCharUpperCase(
                              row?.created?.first_name
                            )} ${firstCharUpperCase(
                              row?.created?.last_name
                            )}`}{" "}
                          {/* &#x2022;{" "} */}
                          {/* {row.uploaded && (
                          <DateFormat
                            date={row.uploaded}
                            format="dd MMM yyyy"
                          />
                        )} */}
                          &#x2022;{" "}
                          {row?.created &&
                            `${HoursMinSecFormat(parseInt(row.duration))}`}
                        </span>
                      </div>
                    </div>
                  </div>
                  <TranscriptionBtn
                    onDelete={onDeleteClick}
                    hasNoteDeletePermission={hasNoteDeletePermission}
                    id={row?.id}
                    status={row?.status}
                    onClick={navigateToTranscript}
                    onClickFailed={onRetryTranscript}
                    isRetryLoading={isRetryLoading}
                    currentRetryId={row?.id}
                    noteType={row?.note_type}
                  />
                </div>
              </>
            }
          />
        ),
      },
      // {
      //   name: "Action",
      //   selector: (row: { id: string | number }) => row.id,
      //   sortable: false,
      //   width: "50px",
      //   cell: (row: {
      //     id: string | number;
      //     status: string;
      //     note_type: string;
      //   }) => (
      //     <TableCellRender
      //       cellRowData={
      //         <TranscriptionBtn
      //           onDelete={onDeleteClick}
      //           hasNoteDeletePermission={hasNoteDeletePermission}
      //           id={row?.id}
      //           status={row?.status}
      //           onClick={navigateToTranscript}
      //           onClickFailed={onRetryTranscript}
      //           isRetryLoading={isRetryLoading}
      //           currentRetryId={row?.id}
      //           noteType={row?.note_type}
      //         />
      //       }
      //     />
      //   ),
      // },
    ],
    [selectedIdsArr, folderName]
  );
  return columnDefs;
};

export default useMeetingNotesColumnSidebar;
