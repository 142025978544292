export const PUBLIC_NAVIGATION = Object.freeze({
  login: "/auth/login",
  register: "/auth/register",
  forgotPassword: "/auth/forgot-password",
  resetPassword: "/auth/set_forgot_password/",
  // notFoundPage: "/404",
  subscription: "/subscription",
  notAuthorized: "/not-authorized",
  verifyAccount: "/auth/account_activation/",
  setPassword: "/auth/set_password/",
  sharedTranscription: (id = ":id") => `/shared/${id}`,
  toomanyrequest: "/toomanyrequest"
});

export const PRIVATE_NAVIGATION = Object.freeze({
  dashboard: { view: "/" },
  // ** transaction on succcess
  transactions: {
    viewSuccess: { view: "/subscription/success" },
    viewFailure: { view: "/subscription/failed" },
    // viewInvoiceList: { view: "/subscription/invoices" },
  },
  adminDashboard: { view: "/admin/dashboard" },
  customer: {
    view: "/admin/customer",
    detail: {
      view: (id = ":id") => `/admin/customer/${id}`,
    },
  },
  adminPrompt: {
    view: "/admin/prompts",
  },
  notFoundPage: { view: "/404" },
  adminNotFoundPage: "/admin/404",
  adminSetting: {
    rolesAndPermissions: {
      view: "/adminsetting/rolesAndPermissions",
    },
    superuser: { view: "/adminsetting/super-admin" },
    invoices: { view: "/admin/invoices" },
    planManagement: { view: "/adminsetting/planmanagement" },
  },
  setting: {
    personalInformation: {
      view: "/setting/personalInformation",
    },
    userManagement: {
      view: "/setting/user",
      detail: {
        view: (id = ":id") => `/setting/user/${id}`,
      },
      edit: {
        view: (id = ":id") => `/setting/user/${id}/edit`,
      },
    },
    rolesAndPermissions: {
      view: "/setting/rolesAndPermissions",
    },
    prompt: {
      view: "/setting/prompts",
    },
    playbook: {
      view: "/setting/playbook",
    },
    subscription: { view: "/setting/subscriptionplans" },
    billingDetails: { view: "/setting/billing" },
    // transactions: {
    //   view: "/setting/transactions",
    // },
  },
  patient: {
    view: "/patient",
    detail: {
      view: (id = ":id") => `/patient/${id}`,
      // appointment: "/patient/appointment",
    },
    recording: (id = ":id") => `/patient/recording/${id}`,
    appointmentDetail: (id = ":id", patientId = ":patientId") =>
      `/patient/${patientId}/appointment/${id}`,
  },
  transcription: {
    view: "/transcription",
    detail: {
      view: (id = ":id") => `/transcription/${id}`,
    },
  },
  recording: {
    view: "/recording",
  },
  notes: {
    view: "/notes",
  },
  allNotes: {
    view: "/allnotes"
  },
  MeetingFolder: {
    view: "/folder",
    detail: {
      view: (id = ":id") => `/folder/${id}`,
    },
    recording: (id = ":id") => `/folder/recording/${id}`,
  },
  content: {
    view: "/content",
  },
  voice: {
    view: "/voice",
  },
});
