import { IconInputProps } from ".";

export const ContentNotesIcon = ({ className }: IconInputProps) => {
  return (
    // <svg
    //   className={className ?? ""}
    //   width="30"
    //   height="30"
    //   viewBox="0 0 30 30"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     className="group-hover:fill-black"
    //     d="M20.625 6.79688H9.375C9.18852 6.79688 9.00968 6.87095 8.87782 7.00282C8.74595 7.13468 8.67188 7.31352 8.67188 7.5V13.125C8.67188 13.3115 8.74595 13.4903 8.87782 13.6222C9.00968 13.754 9.18852 13.8281 9.375 13.8281H20.625C20.8115 13.8281 20.9903 13.754 21.1222 13.6222C21.254 13.4903 21.3281 13.3115 21.3281 13.125V7.5C21.3281 7.31352 21.254 7.13468 21.1222 7.00282C20.9903 6.87095 20.8115 6.79688 20.625 6.79688ZM19.9219 12.4219H10.0781V8.20312H19.9219V12.4219ZM23.4375 3.04688H6.5625C6.12738 3.04688 5.71008 3.21973 5.4024 3.5274C5.09473 3.83508 4.92188 4.25238 4.92188 4.6875V25.3125C4.92188 25.7476 5.09473 26.1649 5.4024 26.4726C5.71008 26.7803 6.12738 26.9531 6.5625 26.9531H23.4375C23.8726 26.9531 24.2899 26.7803 24.5976 26.4726C24.9053 26.1649 25.0781 25.7476 25.0781 25.3125V4.6875C25.0781 4.25238 24.9053 3.83508 24.5976 3.5274C24.2899 3.21973 23.8726 3.04688 23.4375 3.04688ZM23.6719 25.3125C23.6719 25.3747 23.6472 25.4343 23.6032 25.4782C23.5593 25.5222 23.4997 25.5469 23.4375 25.5469H6.5625C6.50034 25.5469 6.44073 25.5222 6.39677 25.4782C6.35282 25.4343 6.32812 25.3747 6.32812 25.3125V4.6875C6.32812 4.62534 6.35282 4.56573 6.39677 4.52177C6.44073 4.47782 6.50034 4.45312 6.5625 4.45312H23.4375C23.4997 4.45312 23.5593 4.47782 23.6032 4.52177C23.6472 4.56573 23.6719 4.62534 23.6719 4.6875V25.3125Z"
    //     fill="#9eadba"
    //   />
    //   <path
    //     className="group-hover:stroke-black"
    //     d="M9 16.35H21"
    //     stroke="#9eadba"
    //     strokeWidth="1.3"
    //     strokeLinecap="round"
    //   />
    //   <path
    //     className="group-hover:stroke-black"
    //     d="M9 19.35H21"
    //     stroke="#9eadba"
    //     strokeWidth="1.3"
    //     strokeLinecap="round"
    //   />
    //   <path
    //     className="group-hover:stroke-black"
    //     d="M9 22.35H16"
    //     stroke="#9eadba"
    //     strokeWidth="1.3"
    //     strokeLinecap="round"
    //   />
    // </svg>

    <svg className={className ?? ""} width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="group-hover:fill-black" fillRule="evenodd" clipRule="evenodd" d="M0 3C0 1.34315 1.34315 0 3 0H13C14.6569 0 16 1.34315 16 3V17.13C16 18.7869 14.6569 20.13 13 20.13H3C1.34315 20.13 0 18.7869 0 17.13V3ZM3 3.6842C3 3.13192 3.44772 2.6842 4 2.6842H12C12.5523 2.6842 13 3.13192 13 3.6842V7.05263C13 7.60491 12.5523 8.05263 12 8.05263H4C3.44772 8.05263 3 7.60491 3 7.05263V3.6842ZM3.67105 9.84216C3.30044 9.84216 3 10.1426 3 10.5132C3 10.8838 3.30044 11.1843 3.67105 11.1843H12.3289C12.6996 11.1843 13 10.8838 13 10.5132C13 10.1426 12.6996 9.84216 12.3289 9.84216H3.67105ZM3 13.6447C3 13.2741 3.30044 12.9736 3.67105 12.9736H12.3289C12.6996 12.9736 13 13.2741 13 13.6447C13 14.0153 12.6996 14.3157 12.3289 14.3157H3.67105C3.30044 14.3157 3 14.0153 3 13.6447ZM3.67105 16.1052C3.30044 16.1052 3 16.4057 3 16.7763C3 17.1469 3.30044 17.4473 3.67105 17.4473H9.33836C9.70897 17.4473 10.0094 17.1469 10.0094 16.7763C10.0094 16.4057 9.70897 16.1052 9.33836 16.1052H3.67105Z" fill="#4b5c6b">
      </path>
    </svg>
  );
};
