import DraggableFileInput from "components/DraggableFileInput";
import AlertModal from "components/modal/AlertModal";
import { allowed_mimetype } from "modules/Patient/sub-modules/Recording/constant";
import { useAddAudioRecordingAPI } from "modules/Patient/sub-modules/Recording/Services";
import { useEffect, useState } from "react";

type uploadfiletype = {
  noteID: string | number | undefined;
  note_type: string;
  refreshTable: () => void;
  setOpenUploadFile: React.Dispatch<React.SetStateAction<boolean>>;
  openUploadFile: boolean;
  name?: string;
};

const UploadDraggableFile = (props: uploadfiletype) => {
  const {
    noteID,
    note_type,
    refreshTable,
    setOpenUploadFile,
    openUploadFile,
    name,
  } = props;
  const [file, setFile] = useState<FileList | undefined>();
  const [fileForSubmit, setFileForSubmit] = useState<FileList | undefined>();
  const formData = new FormData();
  const { addAudioRecordingApi, isLoading } = useAddAudioRecordingAPI();

  useEffect(() => {
    if (fileForSubmit) {
      AudioRecord();
    }
  }, [fileForSubmit]);

  const handleFile = (e: any) => {
    setFileForSubmit(e);
  };

  const AudioRecord = async () => {
    if (fileForSubmit && !formData.get("media_url")) {
      if (fileForSubmit) {
        formData.append(
          "media_url",
          fileForSubmit?.[0],
          fileForSubmit?.[0]?.name
        );
        setFileForSubmit(undefined);
      }
      formData.append("model", "assemblyai");
      formData.append("note_type", note_type);

      if (noteID && (note_type === "meeting" || note_type === "patient")) {
        formData.append("patient_id", String(noteID));
      }
      const { data, error } = await addAudioRecordingApi(formData);
      if (data && !error) {
        setOpenUploadFile(false);
        setFile(undefined);
        setFileForSubmit(undefined);
        refreshTable();
      }
    }
  };

  return (
    <AlertModal
      visible={openUploadFile}
      onClose={() => setOpenUploadFile(!openUploadFile)}
      showCrossIcon
      showCancelButton={false}
    >
      {name && (
        <div className="flex w-fit mx-auto text-indigo-700 bg-indigo-100 rounded-lg px-3 py-2 m-2 truncate max-w-[200px text-ellipsis]">
          <p>{name}</p>
        </div>
      )}
      <DraggableFileInput
        loading={isLoading}
        handleFile={handleFile}
        setFile={setFile}
        file={file}
        allowedTypes={allowed_mimetype}
        isMultiple={false}
        isSubmitDisabled={!noteID}
        note_type="meeting"
      />
    </AlertModal>
  );
};

export default UploadDraggableFile;
