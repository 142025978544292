// ** Packages **
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

// ** Components **
import Sidebar from "./components/Sidebar";
import { getSidebarIsCollapse } from "redux/slices/commonSlice";
import Header from "./components/Header";

// ** Redux **

import { getAuth } from "redux/slices/authSlice";
import Sidebar2 from "./components/Sidebar2";

type Props = {
  children: React.ReactNode;
};

const Layout = (props: Props) => {
  const { children } = props;
  const sidebarIsCollapse = useSelector(getSidebarIsCollapse);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [isSidebarClicked, setIsSidebarClicked] = useState<boolean>(false);
  const [isSecondSidebarClicked, setIsSecondSidebarClicked] =
    useState<boolean>(false);

  const { user } = useSelector(getAuth);
  const isSuperAdmin = user?.is_super_administrator;

  return (
    <div
      className={`main__wrapper ${
        sidebarIsCollapse ? "" : "sidebar__collapse"
      } ${isSidebarClicked ? "close-sidemenu close-sidebar" : ""}`}
    >
      <div className="w-full">
        <>
          <Sidebar
            sidebarRef={sidebarRef}
            headerTitle={`${user?.firstName}`}
            profile={user?.profile}
            setIsSidebarClicked={setIsSidebarClicked}
            setIsSecondSidebarClicked={setIsSecondSidebarClicked}
            isSidebarClicked={isSidebarClicked}
            isSecondSidebarClicked={isSecondSidebarClicked}
          />
          <div
            className={`main_content w-[calc(100%-270px)] max-w-full ml-auto h-full ${
              isSecondSidebarClicked ? "inner-sidebar" : ""
            }`}
          >
            {" "}
            {/* Apply flex directly here */}
            {isSecondSidebarClicked && !isSuperAdmin ? (
              <div className="secondary__content__wrapper secondary-sidebar-active second-sidebar p-4 border-0 border-r border-solid border-grey-200">
                <Sidebar2
                  sidebarRef={sidebarRef}
                  headerTitle={`${user?.firstName}`}
                  profile={user?.profile}
                  setIsSidebarClicked={setIsSidebarClicked}
                  isSidebarClicked={isSidebarClicked}
                  setIsSecondSidebarClicked={setIsSecondSidebarClicked}
                  isSecondSidebarClicked={isSecondSidebarClicked}
                />
              </div>
            ) : null}
            <div
              className={`main__content__wrapper p-0 ${
                isSecondSidebarClicked ? "main-content overflow-auto" : ""
              }`}
            >
              <div className="h-full overflow-auto px-3">{children}</div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

// const Layout = (props: Props) => {
//   const { children } = props;
//   const sidebarIsCollapse = useSelector(getSidebarIsCollapse);
//   const sidebarRef = useRef<HTMLDivElement>(null);
//   const [isSidebarClicked, setIsSidebarClicked] = useState<boolean>(false);
//   const [isSecondSidebarClicked, setIsSecondSidebarClicked] = useState<boolean>(false);
//   useEffect(() => {
//     if (true) {
//       setIsSidebarClicked(false);
//     }
//   }, [sidebarIsCollapse]);
//   const { user } = useSelector(getAuth);
//   return (
//     <div
//       className={`main__wrapper ${
//         sidebarIsCollapse ? "" : "sidebar__collapse"
//       } ${isSidebarClicked ? "close-sidemenu close-sidebar" : ""}`}
//     >
//       <div className="w-full">
//         <>
//           <Sidebar
//             sidebarRef={sidebarRef}
//             headerTitle={`${user?.firstName}`}
//             profile={user?.profile}
//             setIsSidebarClicked={setIsSidebarClicked}
//             setIsSecondSidebarClicked={setIsSecondSidebarClicked}
//             isSidebarClicked={isSidebarClicked}
//           />
//           <div className="flex">
//             {isSecondSidebarClicked ? (
//               <div className="main__content__wrapper secondary-sidebar-active">
//                 <Sidebar2
//                   sidebarRef={sidebarRef}
//                   headerTitle={`${user?.firstName}`}
//                   profile={user?.profile}
//                   setIsSidebarClicked={setIsSidebarClicked}
//                   isSidebarClicked={isSidebarClicked}
//                   setIsSecondSidebarClicked={setIsSecondSidebarClicked}
//                   isSecondSidebarClicked={isSecondSidebarClicked}
//                 />
//               </div>
//             ) : <></>}
//             <div className="main__content__wrapper">
//               <div className="px-[12px] sm:px-1">{children}</div>
//             </div>
//           </div>
//         </>
//       </div>
//     </div>
//   );
// };

export default Layout;
