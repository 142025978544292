import { useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { Check, Edit } from "react-feather";
import {
  useGetAllSubscriptionPlans,
  useGetManagePlans,
  usePostSelectedPlan,
} from "../services";
import { convertTitleCase } from "utils";
import PageLoader from "components/Loaders/PageLoader";
// import Breadcrumbs from "components/Breadcrumbs";
// import { BREAD_CRUMB } from "constants/breadcrumbs.constant";
import Button from "components/Theme/Button";
// import AlertModal from "components/modal/AlertModal";
import {
  planType,
  subscriptionPlansResponseType,
} from "../types/subscriptionTransactionTypes";
import { capitalize } from "lodash";
import { useSelector } from "react-redux";
import {
  AuthUserType,
  getAuth,
  setCurrentUser,
  setLogoutData,
} from "redux/slices/authSlice";
import { useDispatch } from "react-redux";
import { useGetSuperAdminPlans } from "modules/Admin/sub-module/Setting/PlanManagement/services";
import AddUpdatePlan from "modules/Admin/sub-module/Setting/PlanManagement/components/AddUpdatePlan";
import AddNewSuperPlan from "modules/Admin/sub-module/Setting/PlanManagement/components/AddNewSuperPlan";
import useLoginService from "modules/Auth/pages/Login/hooks/useLoginService";
import {
  priceOptionType,
  priceType,
  specificationType,
} from "modules/Admin/sub-module/Setting/PlanManagement/types";
import { useNavigate } from "react-router-dom";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

type subscriptionPlanType = {
  isSuperAdminPlan?: boolean;
};

type filteredType = {
  plan_name: string;
  transcriptAI: string[];
  notesAI: string[];
};

type superPlanData = {
  plan_data: {
    [key: string]: planType[];
  };
  prices: priceType[];
};

const SubscriptionPlan = (props: subscriptionPlanType) => {
  const { user } = useSelector(getAuth);

  const isCreatePlanPermission = user?.permissions?.some((permission, i) => {
    return (
      permission.types === "plan" &&
      permission.permission_name === "create" &&
      permission.status
    );
  });
  const isUpdatePlanPermission = user?.permissions?.some(
    (permission, i) =>
      permission.types === "plan" &&
      permission.permission_name === "update" &&
      permission.status
  );
  // for logout
  const handleLogout = () => {
    if (!isSuperAdminPlan) {
      dispatch(setLogoutData());
    }
  };
  const dispatch = useDispatch();

  // administrator plans
  const {
    getManagePlans,
    isLoading: isManageLoading,
    isError: isManageError,
  } = useGetManagePlans();
  const [frequencies, setFrequencies] = useState<{
    selected: string;
    frequencies: string[];
  }>({
    selected: "",
    frequencies: [],
  });
  const {
    addSubscritionPlanApi,
    isLoading: isLoadingPost,
    isError,
  } = usePostSelectedPlan();
  const [subscriptionsAlert, setSubscriptionAlert] = useState<boolean>(false);

  const [planName, setPlanName] = useState<string>("");
  const [updatePlanData, setUpdatePlanData] = useState<boolean>(false);
  const [addNewPlanData, setAddNewPlanData] = useState<boolean>(false);
  const [selectedPlanData, setSelectedPlanData] = useState<any>({});
  const [subscriptionsData, setSubscriptionData] =
    useState<subscriptionPlansResponseType>();

  const { getAllSubscriptionPlans, isLoading } = useGetAllSubscriptionPlans();
  const { isVerified } = useLoginService();
  const getSubscriptionPlansData = async () => {
    const { data } = await getAllSubscriptionPlans();
    const durations = Object?.keys(data);
    setFrequencies({
      selected: durations?.[0] ?? "",
      frequencies: durations,
    });
    setSubscriptionData(data);
  };

  const navigate = useNavigate();

  // super-admin plans
  const { isSuperAdminPlan } = props;
  const [superPlanData, setSuperPlanData] = useState<any>({});
  const { getSuperAdminPlansApi, isLoading: superPlanLoading } =
    useGetSuperAdminPlans();

  const fetchAdminPlanAPI = async () => {
    const data = await getSuperAdminPlansApi();
    const durations = Object?.keys(data?.data?.plan_data);
    setFrequencies({
      selected: durations?.[0] ?? "",
      frequencies: durations,
    });
    setSubscriptionData(data?.data?.plan_data);
    setSuperPlanData(data?.data);
  };

  const results = subscriptionsData?.[frequencies?.selected]?.map(
    (plan: planType) => {
      const filtered: filteredType = {
        plan_name: plan?.plan_name,
        transcriptAI: [],
        notesAI: [],
      };
      plan?.specification?.forEach((item: specificationType) => {
        if (item?.name_type === "TranscriptAI") {
          filtered.transcriptAI.push(item?.name);
        } else if (item?.name_type === "NotesAI") {
          filtered.notesAI.push(item?.name);
        }
      });

      return filtered;
    }
  );

  // // Extract data based on the specifications array
  subscriptionsData?.[frequencies?.selected]?.forEach((plan, i) => {
    if (plan?.price_id?.toLowerCase() === "free") {
      const element = subscriptionsData?.[frequencies?.selected]?.splice(i, 1);
      subscriptionsData?.[frequencies?.selected].unshift(element?.[0]);
      return;
    }
  });

  results?.forEach((plan, i) => {
    if (plan?.plan_name?.toLowerCase() === "free") {
      const element = results?.splice(i, 1);
      results?.unshift(element?.[0]);
      return;
    }
  });

  useEffect(() => {
    if (isSuperAdminPlan) {
      fetchAdminPlanAPI();
    } else {
      getSubscriptionPlansData();
    }
  }, []);

  const addSubscritionPlanHandler = async (payload: { plan: string }) => {
    setSubscriptionAlert(true);
    const response = await addSubscritionPlanApi(payload);
    const data = response.data;
    if (!isError && data) {
      const new_planData = { ...user?.current_plan?.payment_data, plan: data };
      const new_paymentData = {
        ...user?.current_plan,
        payment_data: new_planData,
      };
      const new_currentPlan: any = { ...user, current_plan: new_paymentData };
      dispatch(setCurrentUser({ user: new_currentPlan }));

      if (data?.payment_url) {
        window.location = data.payment_url;
      }
    }
    //  else {
    //   navigate("/subscription/failed");
    // }
  };

  const selectedDuarationPlan = (option: string) => {
    setFrequencies({ ...frequencies, selected: option });
  };

  const alertSubmit = async () => {
    await addSubscritionPlanHandler({ plan: planName });
    setSubscriptionAlert(false);
  };

  const managePlansHandler = async () => {
    const { data } = await getManagePlans();
    if (!isManageError && data) {
      window.location.href = data;
    }
  };

  const currentPlanName = user?.current_plan?.payment_data?.plan?.plan_name;

  return (
    <div className="content-center h-[calc(100dvh-50px)]">
      {/* <Breadcrumbs path={BREAD_CRUMB.SubscriptionPlans} /> */}
      {isLoading || superPlanLoading ? (
        <PageLoader />
      ) : (
        <div className="mx-auto w-full ">
          {/* <AlertModal
            onCancel={() => setSubscriptionAlert(false)}
            onClose={() => setSubscriptionAlert(false)}
            onSubmit={alertSubmit}
            showFooter
            showCrossIcon
            visible={subscriptionsAlert}
            submitButtonText={currentPlanName ? "Yes" : "Buy"}
            submitLoading={isLoadingPost}
          >
            {currentPlanName ? (
              <div className="text-center">
                <p>
                  Do you want to switch from{" "}
                  <span className="text-green-600 font-bold">
                    {capitalize(currentPlanName)}
                  </span>{" "}
                  to{" "}
                  <span className="text-blue-600 font-bold">
                    {capitalize(planName)}
                  </span>
                  ?{" "}
                </p>
              </div>
            ) 
            : (
              <div className="text-center">
                Do you want to buy this{" "}
                <span className="text-blue-700 font-bold">
                  {capitalize(planName)}
                </span>{" "}
                Plan?
              </div>
            )
            }
          </AlertModal> */}
          {updatePlanData || addNewPlanData ? (
            addNewPlanData ? (
              <AddNewSuperPlan
                planData={superPlanData}
                setUpdateAddBack={setAddNewPlanData}
              />
            ) : (
              <AddUpdatePlan
                addPlan={false}
                planData={superPlanData}
                setSuperPlanData={setSuperPlanData}
                selectedPlanData={selectedPlanData}
                setUpdateAddBack={setUpdatePlanData}
              />
            )
          ) : (
            <>
              {subscriptionsData?.[frequencies?.selected]?.length && (
                <div className="">
                  {isSuperAdminPlan ? (
                    <div className="mx-auto max-w-4xl my-[25px] text-center">
                      <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-[21px] md:text-[30px]">
                        Plan Management
                      </p>
                      {isCreatePlanPermission ? (
                        <div className="my-4">
                          <Button
                            className="bg-indigo-500 text-white"
                            onClick={() => setAddNewPlanData(true)}
                          >
                            Add New Plan
                          </Button>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <div className="mx-auto mb-3 text-center">
                      <p className="text-4xl font-bold tracking-tight text-gray-900 sm:text-[21px] md:text-[30px] ">
                        Subscription Plans
                      </p>
                    </div>
                  )}
                  <p className="mx-auto text-center text-lg leading-8 text-gray-600 max-w-[70%] lg:max-w-full">
                    Choose the plan that serves and enhance your professional
                    requiments and boost your client satisfaction
                  </p>
                  <div className="flex justify-center">
                    {setSubscriptionData?.length > 1 && (
                      <fieldset aria-label="Payment frequency">
                        <RadioGroup
                          value={frequencies?.selected ?? undefined}
                          onChange={selectedDuarationPlan}
                          className="flex flex-wrap gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
                        >
                          {frequencies?.frequencies?.map((option) => (
                            <RadioGroup.Option
                              key={option}
                              value={option}
                              className="cursor-pointer rounded-full px-2.5 py-1 text-black aria-checked-[checked]:bg-indigo-600 aria-checked-[checked]:text-white"
                            >
                              <>{capitalize(option)}</>
                            </RadioGroup.Option>
                          ))}
                        </RadioGroup>
                      </fieldset>
                    )}
                  </div>
                </div>
              )}

              {/* <div className="isolate mt-8 flex flex-wrap gap-8 justify-center w-full overflow-y-auto h-[68vh]"> */}
              <div className="subscription__price mt-4  w-full ">
                <div
                  className={`subscription__price___content flex gap-8 m-auto lg:flex-wrap ${
                    !user?.current_plan?.active
                      ? "justify-center"
                      : "justify-start"
                  }`}
                >
                  {subscriptionsData?.[frequencies?.selected]?.length ? (
                    subscriptionsData?.[frequencies?.selected]?.map(
                      (tier: planType, i: number) => (
                        <div
                          key={tier?.id}
                          className={classNames(
                            // tier.mostPopular
                            "subscription__rate border border-solid border-indigo-600",
                            `rounded-3xl p-8 xl:p-5 ${
                              subscriptionsData?.[frequencies?.selected]
                                ?.length <= 3
                                ? "plan__three w-[33.33%] max-w-full lg:w-[calc(50%-16px)] md:w-full"
                                : "plan__five w-[calc(20%-26px)] max-w-full 4xl:w-[calc(25%-24px)] 3xl:w-[calc(33.33%-22px)] lg:w-[calc(50%-16px)] md:w-full"
                            }  sm:w-full`,
                            `${
                              currentPlanName === tier?.plan_name
                                ? " bg-indigo-50"
                                : ""
                            }`
                          )}
                        >
                          <div
                            id={`${tier?.id}`}
                            className={classNames(
                              // tier.mostPopular
                              "text-indigo-600",
                              "text-lg font-semibold leading-8 flex flex-wrap-reverse justify-between"
                            )}
                          >
                            <p>{convertTitleCase(tier?.plan_name)}</p>
                            <p className="px-2 rounded-xl text-white text-[11px] bg-green-500 self-center">
                              {currentPlanName === tier?.plan_name && (
                                <>Active Plan</>
                              )}
                            </p>

                            {isUpdatePlanPermission && isSuperAdminPlan && (
                              <Edit
                                className="text-xs cursor-pointer w-5 h-5"
                                onClick={() => {
                                  setSelectedPlanData(tier);
                                  setUpdatePlanData(true);
                                }}
                              />
                            )}
                          </div>
                          <p className="mt-4 text-sm leading-6 text-gray-600">
                            {tier?.description}
                          </p>
                          <p className="mt-6 flex flex-wrap items-baseline gap-x-1">
                            <span className="text-4xl font-bold tracking-tight text-gray-900">
                              ${tier?.price}
                            </span>
                            <span className="text-sm font-semibold leading-6 text-gray-600">
                              /{capitalize(frequencies?.selected)}
                            </span>
                          </p>

                          {isSuperAdminPlan ? (
                            <></>
                          ) : (
                            // <div className="my-3">
                            //   <SelectField
                            //     isLoading={isLoading}
                            //     value={{label: "199", value: "ffdfd"}}
                            //     name="defaultValSelect"
                            //     // options={superPlanData?.prices?.filter((object:any, i:number) => {return object.price_id})}
                            //     options={[{label: "10", value: "10"}, {label: "20", value: "20"}, {label: "30", value: "30"},{label: "40", value: "40"} ]}
                            //     onChange={(e: { value: string }) => {
                            //       console.log(e.value)
                            //     }}
                            //   />
                            // </div>
                            <Button
                              className={
                                !isManageLoading
                                  ? classNames(
                                      `${
                                        currentPlanName === tier?.plan_name
                                          ? "bg-indigo-400"
                                          : "bg-indigo-600 hover:bg-indigo-500 cursor-pointer"
                                      } text-white shadow-sm  mt-6 rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 w-full`
                                    )
                                  : classNames(
                                      "bg-indigo-500 text-white shadow-sm mt-6 rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 cursor-pointer w-full"
                                    )
                              }
                              onClick={async () => {
                                if (currentPlanName !== tier?.plan_name) {
                                  if (!isManageLoading) {
                                    // if (currentPlanName) {
                                    //   setPlanName(tier?.plan_name);
                                    //   managePlansHandler();
                                    // } else {
                                    setPlanName(tier?.plan_name);
                                    await addSubscritionPlanHandler({
                                      plan: tier?.plan_name,
                                    });
                                    setSubscriptionAlert(true);
                                    // }
                                    // if (currentPlanName === tier?.plan_name) {
                                    //   setPlanName(tier?.plan_name);
                                    //   managePlansHandler();
                                    // } else {
                                    //   setPlanName(tier?.plan_name);
                                    //   setSubscriptionAlert(true);
                                    // }
                                  }
                                }
                              }}
                              loading={
                                planName === tier?.plan_name
                                  ? isManageLoading
                                    ? true
                                    : isLoadingPost
                                  : false
                              }
                            >
                              {/* {currentPlanName ? <>Manage Plan</> : <>Buy Plan</>} */}
                              {currentPlanName === tier?.plan_name ? (
                                <>Current Plan</>
                              ) : user?.current_plan ? (
                                <>Choose Plan</>
                              ) : (
                                <>Buy plan</>
                              )}
                            </Button>
                          )}

                          <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                            {tier?.specification?.map(
                              (feature, idx) =>
                                feature?.limit !== "false" &&
                                feature?.limit !== "0" &&
                                feature?.name !== "Add user" &&
                                feature?.name_type !== "NotesAI" &&
                                feature?.name_type !== "TranscriptAI" && (
                                  <li
                                    key={feature.name}
                                    className="flex gap-x-3"
                                  >
                                    <Check
                                      aria-hidden="true"
                                      className="h-6 w-5 flex-none text-indigo-600"
                                    />
                                    {capitalize(
                                      feature.name
                                        .replace("-1", "unlimited")
                                        .replace("-", "")
                                    )}
                                  </li>
                                )
                            )}
                            <li className="flex gap-x-3">
                              <Check
                                aria-hidden="true"
                                className="h-6 w-5 flex-none text-indigo-600"
                              />
                              <div>
                                <p className="font-semibold">
                                  Transcript Models
                                </p>
                                <p>
                                  {results?.[i]?.transcriptAI?.map(
                                    (model: string, idx: number) =>
                                      results?.[i]?.transcriptAI?.length - 1 ===
                                      idx
                                        ? `${capitalize(model)}`
                                        : `${capitalize(model)}, `
                                  )}
                                </p>
                              </div>
                            </li>
                            <li className="flex gap-x-3">
                              <Check
                                aria-hidden="true"
                                className="h-6 w-5 flex-none text-indigo-600"
                              />
                              <div>
                                <p className="font-semibold">Notes Models</p>
                                <p>
                                  {results?.[i]?.notesAI?.map(
                                    (model: string, idx: number) =>
                                      results?.[i]?.notesAI?.length - 1 === idx
                                        ? `${capitalize(model)}`
                                        : `${capitalize(model)}, `
                                  )}
                                </p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      )
                    )
                  ) : (
                    <div className="mt-[20%] flex">
                      <p className="text-indigo-600 text-[30px] font-bold">
                        Sorry No Plans To Show At This Moment, Retry Again Later
                      </p>
                    </div>
                  )}
                </div>
                {!isSuperAdminPlan && currentPlanName && (
                  <div className="max-w-[80%] mt-8 mx-auto text-right xl:max-w-full">
                    <Button
                      className="bg-indigo-500 text-white w-44"
                      onClick={() => navigate("/")}
                    >
                      Go Back
                    </Button>
                  </div>
                )}
              </div>
            </>
          )}

          {!isSuperAdminPlan && !currentPlanName && (
            <div
              className="bg-black w-[200px] text-center text-white px-2 py-1 rounded-xl mt-5 hover:opacity-[0.8] cursor-pointer mx-auto"
              onClick={() => handleLogout()}
            >
              Logout
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SubscriptionPlan;
